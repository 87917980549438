export const SOURCE_TYPE = [
  { id: 1, label: "Virtual", value: "virtual" },
  { id: 2, label: "Fisico", value: "physical" },
]

export const TYPE_DINAMIC = [
  { id: 1, label: "string", value: "string" },
  { id: 2, label: "boolean", value: "boolean" },
  { id: 3, label: "number", value: "number" },
  { id: 4, label: "array-string", value: "array-string" },
]

const parsedDinamicDataOnSave = (field: any) => {
  let values: any = {}
  ;(field || []).map((item: any) => {
    if (item?.key?.includes(".")) {
      let [key1, key2] = item?.key?.split(".")
      if (values.hasOwnProperty(key1)) {
        values[key1] = { ...values[key1], [key2]: assignDataType(item.type, item.value) }
      } else {
        Object.assign(values, { [key1]: { [key2]: assignDataType(item.type, item.value) } })
      }
      return true
    }
    Object.assign(values, { [item.key]: assignDataType(item.type, item.value) })
    return true
  })
  return values
}

const assignDataType = (type: any, value: any) => {
  try {
    switch (type) {
      case "array-string":
        return value && value?.split("+")
      case "number":
        return Number(value) || 0
      case "string":
        return value?.toString() || JSON.stringify(value)
      default:
        return JSON.parse(value)
    }
  } catch (error) {
    return value
  }
}

export const TYPE = [
  { id: 1, label: "string", value: "string" },
  { id: 2, label: "boolean", value: "boolean" },
  { id: 3, label: "number", value: "number" },
  { id: 4, label: "array-string", value: "array-string" },
]

const ASSING_TYPEOF_LOAD = (value: any) => {
  try {
    const val = typeof value
    switch (val) {
      case "boolean":
        return "boolean"
      case "number":
        return "number"
      case "string":
        return "string"
      case "object":
        if (Array.isArray(value)) return "array-string"
        return "string"
      default:
        return "string"
    }
  } catch (error) {
    console.log("ERR", value)
    return value
  }
}

export const fill_form_from_data = (_data: any) => {
  const {
    id,
    name,
    alias,
    type,
    physicalLink,
    location,
    safety,
    ranking,
    storePickup,
    enabled,
    contact,
    custom,
    volumeCapacity,
    dispatchCapacity,
    schedule,
    crossdock,
    workingSessions,
    workingTime,
    calendarSettings,
  } = _data

  let data = {
    // 1
    id,
    name,
    alias: alias[0] || "",
    type,
    physicalLink,
    // 2
    location: Object.entries(location).map(([key, value]) => ({
      key,
      value,
      type: TYPE.find((ty: any) => ty?.value === ASSING_TYPEOF_LOAD(value))?.value,
    })),
    // 3
    safety,
    ranking,
    storePickup,
    enabled,
    // 4
    contact: {
      name: contact?.name,
      phone: contact?.phone,
    },
    // 5
    custom: Object.entries(custom).map(([key, value]) => {
      return {
        key,
        value,
        type: TYPE.find((ty: any) => ty?.value === ASSING_TYPEOF_LOAD(value))?.value,
      }
    }),
    // 6
    volumeCapacity: (volumeCapacity || [])?.map((vc: any) => ({
      daysOfWeek: vc?.daysOfWeek.map((day: any) => Number(day)),
      overCapacity: Number(vc?.overCapacity),
      capacity: Number(vc?.capacity),
    })),
    // 7
    dispatchCapacity: (dispatchCapacity || [])?.map((dp: any) => ({
      daysOfWeek: dp?.daysOfWeek.map((day: any) => Number(day)),
      deliveryType: dp?.deliveryType,
      productType: dp?.productType ? dp.productType?.join(",") : "",
      overCapacity: Number(dp?.overCapacity),
      capacity: Number(dp?.capacity),
    })),
    // 8
    schedule: (schedule || [])?.map((sch: any) => ({
      deliveryType: sch?.deliveryType,
      shippingType: sch?.shippingType ? sch?.shippingType?.map((shp: any) => shp) : [],
      productType: sch?.productType && sch?.productType?.join(","),
      leadtime: {
        value: Number(sch?.leadtime?.value),
        unit: sch?.leadtime?.unit,
      },
      slots: (sch?.slots || [])?.map((slot: any) => {
        return {
          from: slot?.from,
          to: slot?.to,
          label: slot?.label,
        }
      }),
    })),
    // 9
    crossdock: (crossdock || []).map((crss: any) => ({
      productType: crss?.productType && crss?.productType?.join(","),
      value: Number(crss?.value),
      unit: crss?.unit,
    })),
    // 10
    workingSessions: (workingSessions || []).map((ws: any) => {
      let wsData = {
        productType: ws?.productType && ws?.productType?.join(","),
        capacity: Number(ws?.capacity),
        daysOfWeek: ws?.daysOfWeek.map((day: any) => Number(day)),
        from: ws?.from,
        to: ws?.to,
      }
      if (Boolean(ws?.lockTime)) {
        wsData = Object.assign(wsData, {
          enable_locktime: true,
          lockTime: {
            from: ws.lockTime?.from,
            to: ws.lockTime?.to,
          },
        })
      }
      return wsData
    }),
    // 11
    workingTime: (workingTime || [])?.map((wt: any) => ({
      daysOfWeek: wt?.daysOfWeek.map((day: any) => Number(day)),
      from: wt?.from,
      to: wt?.to,
    })),
    // 12
    calendarSettings: {
      timezone: calendarSettings?.timezone,
      nonWorkingDays: calendarSettings?.nonWorkingDays?.map((day: any) => Number(day)) || [],
      nonWorkingDates: calendarSettings?.nonWorkingDates || [],
    },
  }
  return data
}

export const parsed_body_to_send = (_data: any) => {
  const {
    id,
    name,
    alias,
    type,
    physicalLink,
    location,
    safety,
    ranking,
    storePickup,
    enabled,
    contact,
    custom,
    volumeCapacity,
    dispatchCapacity,
    schedule,
    crossdock,
    workingSessions,
    workingTime,
    calendarSettings,
  } = _data

  let data = {
    OIM: {
      // 1
      id,
      name,
      alias: [alias || ""],
      type,
      physicalLink: type === "virtual" ? physicalLink : "",
      // 2
      location: parsedDinamicDataOnSave(location),
      // 3
      safety: Number(safety),
      ranking: Number(ranking),
      storePickup,
      enabled,
      // 4
      contact,
      // 5
      custom: parsedDinamicDataOnSave(custom),
    },
    OLM: {
      // 1
      id,
      name,
      alias: [alias || ""],
      // 6
      volumeCapacity: (volumeCapacity || [])?.map((vc: any) => ({
        daysOfWeek: vc?.daysOfWeek.map((day: any) => Number(day)),
        overCapacity: Number(vc?.overCapacity),
        capacity: Number(vc?.capacity),
      })),
      // 7
      dispatchCapacity: (dispatchCapacity || [])?.map((dp: any) => ({
        daysOfWeek: dp?.daysOfWeek.map((day: any) => Number(day)),
        deliveryType: dp?.deliveryType,
        productType: dp.productType && dp.productType?.split(","),
        overCapacity: Number(dp?.overCapacity),
        capacity: Number(dp?.capacity),
      })),
      // 8
      schedule: (schedule || [])?.map((sch: any) => ({
        deliveryType: sch?.deliveryType,
        shippingType: sch?.shippingType ? sch?.shippingType?.map((shp: any) => shp) : [],
        productType: sch.productType && sch.productType?.split(","),
        leadtime: {
          value: Number(sch?.leadtime?.value),
          unit: sch?.leadtime?.unit,
        },
        slots: sch?.slots?.map((slot: any) => {
          return {
            from: slot?.from,
            to: slot?.to,
            label: slot?.label,
          }
        }),
      })),
      // 9
      crossdock: (crossdock || []).map((crss: any) => ({
        productType: crss.productType && crss.productType?.split(","),
        value: Number(crss?.value),
        unit: crss?.unit,
      })),
      // 10
      workingSessions: (workingSessions || []).map((ws: any) => {
        let wsData = {
          productType: ws.productType && ws.productType?.split(","),
          capacity: Number(ws?.capacity),
          daysOfWeek: ws?.daysOfWeek.map((day: any) => Number(day)),
          from: ws.from,
          to: ws.to,
        }
        if (Boolean(ws.enable_locktime)) {
          wsData = Object.assign(wsData, {
            lockTime: {
              from: ws.lockTime?.from,
              to: ws.lockTime?.to,
            },
          })
        }
        return wsData
      }),
      // 11
      workingTime: (workingTime || [])?.map((wt: any) => ({
        daysOfWeek: wt?.daysOfWeek.map((day: any) => Number(day)),
        from: wt?.from,
        to: wt?.to,
      })),
      // 12
      calendarSettings: {
        timezone: calendarSettings?.timezone,
        nonWorkingDays: calendarSettings?.nonWorkingDays?.map((day: any) => Number(day)) || [],
        nonWorkingDates: calendarSettings?.nonWorkingDates || [],
      },
    },
  }

  return data
}
