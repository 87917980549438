import { IFiltersTable } from "interfaces/IFilters"
import { FiltersActionsType } from "store/types/AppContextType"

export const FiltersReducer = (state: IFiltersTable, action: FiltersActionsType) => {
  switch (action.type) {
    case "ADD_FILTER":
      return { ...state, ...action.payload }
    case "DELETE_FILTER":
      const keyToRemove: any = action.payload
      const { [keyToRemove]: removed, ...newState } = state
      return newState
    case "CLEAR_FILTERS":
      return {}
    default:
      return state
  }
}
