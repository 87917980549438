import { AddButton, FormSection, Select, Switch, TextField } from "components/Core"
import { Controller } from "react-hook-form"
import { DAYS_OF_WEEK_NUMBER, SHIPPING_TYPES, UNIT_TIME } from "constants/FORM_CONSTANTS"
import { Coverages } from "components/DinamicFormSections"
import { useEffect, useState } from "react"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"
import Swal from "sweetalert2"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"

interface IFormCoverage {
  control: any
  errors: any
  working_time_field_form: any
  cost_field_form: any
}

const FormCoverage = ({ control, errors, working_time_field_form, cost_field_form }: IFormCoverage) => {
  const [delivery_types, set_delivery_types] = useState([])
  const { data: data_schedules, search_osrm_action: search_osrm_action_schedules } = useSearchMiddlewareOSRM({
    entity: "schedules",
  })

  useEffect(() => {
    search_osrm_action_schedules({ from: 0, size: 1000, fields: ["id", "deliveryType"] })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const get_delivery_types_action = async () => {
      try {
        let schedule_from_req = data_schedules?.records
        set_delivery_types(
          schedule_from_req?.map((sch: any) => ({
            id: sch?.id,
            label: sch?.deliveryType,
            value: sch?.deliveryType,
          })),
        )
      } catch (error: any) {
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    get_delivery_types_action()
    // eslint-disable-next-line
  }, [data_schedules])

  const add_new_working_time = () =>
    working_time_field_form.append({
      daysOfWeek: [],
      from: "",
      to: "",
    })

  const add_new_cost = () => cost_field_form.append({ key: "", value: 0 })

  const validationsSectionsErrors = {
    1: Boolean(["id", "status"].some((key: string) => Object.keys(errors).includes(key))),
    2: Boolean(["routeId", "courierId"].some((key: string) => Object.keys(errors).includes(key))),
    3: Boolean(["shippingType", "deliveryType"].some((key: string) => Object.keys(errors).includes(key))),
    4: Boolean(["deliveryDays", "pickupDays"].some((key: string) => Object.keys(errors).includes(key))),
    5: Boolean(["leadtime"].some((key: string) => Object.keys(errors).includes(key))),
    6: Boolean(["workingTime"].some((key: string) => Object.keys(errors).includes(key))),
    7: Boolean(["cost"].some((key: string) => Object.keys(errors).includes(key))),
  }

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection
        title="Información general"
        helperText="Información general"
        number={1}
        anyError={validationsSectionsErrors[1]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="id"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Id Cobertura" onChange={onChange} value={value} />
              )}
            />
          </div>
          <div className="flex flex-col lg:flex-row gap-4">
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Switch label="Estado de la cobertura" onChange={onChange} checked={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[1] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Detalle de cobertura"
        helperText="Detalle de cobertura"
        number={2}
        anyError={validationsSectionsErrors[2]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="routeId"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => <TextField label="Id Ruta" onChange={onChange} value={value} />}
          />
          <Controller
            name="courierId"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField label="Id Courier" onChange={onChange} value={value} />
            )}
          />
          {validationsSectionsErrors[2] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Detalles de Entrega y Despacho"
        helperText="Detalles de Entrega y Despacho"
        number={3}
        anyError={validationsSectionsErrors[3]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="shippingType"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select label="Tipo de envío" options={SHIPPING_TYPES} onChange={onChange} value={value} multi />
              )}
            />
            <Controller
              name="deliveryType"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select label="Tipo de entrega" options={delivery_types} onChange={onChange} value={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[3] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Entrega y/o recogida"
        helperText="Entrega y/o recogida"
        number={4}
        anyError={validationsSectionsErrors[4]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name={`deliveryDays`}
            defaultValue={[]}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select label="Días de entrega" options={DAYS_OF_WEEK_NUMBER} onChange={onChange} value={value} multi />
            )}
          />
          <Controller
            name={`pickupDays`}
            defaultValue={[]}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select label="Días de recogida" options={DAYS_OF_WEEK_NUMBER} onChange={onChange} value={value} multi />
            )}
          />
          {validationsSectionsErrors[4] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection title="Leadtime" helperText="Leadtime" number={5} anyError={validationsSectionsErrors[5]}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="leadtime.value"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Cantidad de tiempo" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="leadtime.unit"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select label="Unidad de tiempo" options={UNIT_TIME} onChange={onChange} value={value} />
              )}
            />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Jornada de operación de cobertura"
        helperText="Jornada de operación de cobertura"
        number={6}
        anyError={validationsSectionsErrors[6]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {working_time_field_form.fields.map((workingTimeItem: any, i: any) => {
            return (
              <Coverages.WorkingTimeFields
                key={workingTimeItem.id}
                index={i}
                working_time={working_time_field_form}
                control={control}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_working_time} />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Costos de cobertura"
        helperText="Costos de cobertura"
        number={7}
        anyError={validationsSectionsErrors[7]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {cost_field_form.fields.map((costItem: any, i: any) => {
            return (
              <Coverages.CostsFields
                key={costItem.id}
                index={i}
                cost={cost_field_form}
                control={control}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_cost} />
          </div>
        </div>
      </FormSection>
    </div>
  )
}

export default FormCoverage
