import logo_omnix_white from "assets/img/logo_omnix_white.svg"
import usePreRender from "hooks/usePreRender"
import { useEffect } from "react"
import { PiSpinnerGapBold } from "react-icons/pi"

const LoadingPreRender = () => {
  const { loadedPreRender, handle_prerendered_action } = usePreRender()

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (!loadedPreRender) {
      timeout = setTimeout(() => handle_prerendered_action(true), 2000)
    }
    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [])

  return (
    <div className="w-screen h-screen grid place-content-center bg_core">
      <div className="flex flex-col items-center justify-center gap-4 lg:gap-6">
        <img alt="logo" className="w-60 lg:w-96" src={logo_omnix_white} />
        <div className="flex flex-row items-center justify-center gap-2 text-white">
          <p>Estamos configurando todo para ti...</p>
          <PiSpinnerGapBold className="text-xl font-bold animate-[rotate_3s_linear_infinite]" />
        </div>
      </div>
    </div>
  )
}

export default LoadingPreRender
