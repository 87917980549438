import { FormSection, TextField, Select, AddButton, Switch } from "components/Core"
import { Controller } from "react-hook-form"
import { Sources } from "components/DinamicFormSections"
import { DAYS_OF_WEEK_NUMBER } from "constants/FORM_CONSTANTS"
import CalendarSettingsDatesComponent from "components/CalendarSettingsDatesComponent"
import time_zone from "helpers/time_zone_helper"
import { SOURCE_TYPE } from "components/FormEntities/FormSource/source.config"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"

interface IFormSource {
  control: any
  errors: any
  watch: any
  setValue: any
  location_field_form: any
  custom_field_form: any
  volume_capacity_field_form: any
  dispatch_capacity_field_form: any
  schedule_field_form: any
  crossdock_field_form: any
  working_sessions_field_form: any
  working_time_field_form: any
}

const FormSource = ({
  control,
  errors,
  watch,
  setValue,
  location_field_form,
  custom_field_form,
  volume_capacity_field_form,
  dispatch_capacity_field_form,
  schedule_field_form,
  crossdock_field_form,
  working_sessions_field_form,
  working_time_field_form,
}: IFormSource) => {
  const [delivery_type, set_delivery_type] = useState([])
  const { data: data_schedules, search_osrm_action: search_osrm_action_schedules } = useSearchMiddlewareOSRM({
    entity: "schedules",
  })
  const type = watch("type")
  const add_new_location = () => location_field_form.append({ type: "string", key: "", value: "" })
  const add_new_custom = () => custom_field_form.append({ type: "string", key: "", value: "" })
  const add_new_volume_capacity = () =>
    volume_capacity_field_form.append({ daysOfWeek: [], capacity: "", overCapacity: "" })
  const add_new_dispatch_capacity = () =>
    dispatch_capacity_field_form.append({
      daysOfWeek: [],
      deliveryType: "",
      productType: "",
      dispatch: "",
      overCapacity: "",
    })
  const add_new_schedule = () =>
    schedule_field_form.append({
      deliveryType: "",
      shippingType: [],
      productType: "",
      leadtime: {
        value: "",
        unit: "",
      },
      slots: [{ from: "", to: "", label: "" }],
    })
  const add_new_crossdock = () =>
    crossdock_field_form.append({
      productType: "",
      value: "",
      unitValue: "",
    })
  const add_new_working_sessions = () =>
    working_sessions_field_form.append({
      daysOfWeek: [],
      productType: "",
      capacity: "",
      from: "",
      to: "",
      lockTime: { from: "", to: "" },
    })
  const add_new_working_time = () =>
    working_time_field_form.append({
      daysOfWeek: [],
      from: "",
      to: "",
    })

  useEffect(() => {
    search_osrm_action_schedules({ from: 0, size: 1000, fields: ["id", "deliveryType"] })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const get_delivery_types_action = async () => {
      try {
        let schedule_from_req = data_schedules?.records
        set_delivery_type(
          schedule_from_req?.map((sch: any) => ({
            id: sch?.id,
            label: sch?.deliveryType,
            value: sch?.deliveryType,
          })),
        )
      } catch (error: any) {
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    get_delivery_types_action()
    // eslint-disable-next-line
  }, [data_schedules])

  const validationsSectionsErrors = {
    1: Boolean(
      ["id", "name", "alias", "type", "physicalLink"].some((key: string) => Object.keys(errors).includes(key)),
    ),
    2: false,
    3: Boolean(
      ["safety", "ranking", "storePickup", "type", "enabled"].some((key: string) => Object.keys(errors).includes(key)),
    ),
    4: Boolean(["contact"].some((key: string) => Object.keys(errors).includes(key))),
    5: false,
    6: Boolean(["volumeCapacity"].some((key: string) => Object.keys(errors).includes(key))),
    7: Boolean(["dispatchCapacity"].some((key: string) => Object.keys(errors).includes(key))),
    8: Boolean(["schedule"].some((key: string) => Object.keys(errors).includes(key))),
    9: Boolean(["crossdock"].some((key: string) => Object.keys(errors).includes(key))),
    10: Boolean(["workingSession"].some((key: string) => Object.keys(errors).includes(key))),
    11: Boolean(["workingTime"].some((key: string) => Object.keys(errors).includes(key))),
    12: Boolean(["calendarSettings"].some((key: string) => Object.keys(errors).includes(key))),
  }

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection
        title="Información general"
        helperText="Información general"
        number={1}
        classNameContent="p-4"
        anyError={validationsSectionsErrors[1]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="id"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => <TextField label="Id" onChange={onChange} value={value} />}
          />
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Nombre del source" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="alias"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Alias (opcional)" onChange={onChange} value={value} />
              )}
            />
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="type"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select label="Tipo" options={SOURCE_TYPE} onChange={onChange} value={value} />
              )}
            />
            {type === "virtual" ? (
              <Controller
                name="physicalLink"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <TextField label="Enlace físico" onChange={onChange} value={value} />
                )}
              />
            ) : null}
          </div>
          {Boolean(validationsSectionsErrors[1]) ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection title="Ubicación" helperText="Ubicación" number={2} anyError={validationsSectionsErrors[2]}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {location_field_form.fields.map((locationItem: any, i: number) => {
            return (
              <Sources.LocationFields
                key={locationItem.id}
                index={i}
                location={location_field_form}
                control={control}
                watch={watch}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_location} />
          </div>
        </div>
      </FormSection>
      <FormSection title="Stock" helperText="Stock" number={3} anyError={validationsSectionsErrors[3]}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="safety"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField type="number" label="Asignar stock de seguridad" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="ranking"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField type="number" label="Ranking" onChange={onChange} value={value} />
              )}
            />
          </div>
          <div className="flex flex-col lg:flex-row gap-4">
            <Controller
              name="storePickup"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Switch label="Retiro en tienda" onChange={onChange} checked={value} />
              )}
            />
            <Controller
              name="enabled"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Switch label="Estado del source" onChange={onChange} checked={value} />
              )}
            />
          </div>
          {Boolean(validationsSectionsErrors[3]) ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection title="Contacto" helperText="Contacto" number={4} anyError={Boolean(validationsSectionsErrors[4])}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="contact.name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Nombre" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="contact.phone"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField type="number" label="Número de contacto" onChange={onChange} value={value} />
              )}
            />
          </div>
          {Boolean(validationsSectionsErrors[4]) ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection title="Custom" helperText="Custom" number={5} anyError={validationsSectionsErrors[5]}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {custom_field_form.fields.map((customItem: any, i: number) => {
            return (
              <Sources.CustomFields
                key={customItem.id}
                index={i}
                custom={custom_field_form}
                control={control}
                errors={errors}
                watch={watch}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_custom} />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Capacidad de volumen"
        helperText="Capacidad de volumen"
        number={6}
        anyError={validationsSectionsErrors[6]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {volume_capacity_field_form.fields.map((volumeCapacityItem: any, i: number) => {
            return (
              <Sources.VolumeCapacityFields
                key={volumeCapacityItem.id}
                index={i}
                volume_capacity={volume_capacity_field_form}
                control={control}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_volume_capacity} />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Capacidad de despacho"
        helperText="Capacidad de despacho"
        number={7}
        anyError={validationsSectionsErrors[7]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {dispatch_capacity_field_form.fields.map((dispatchCapacityItem: any, i: number) => {
            return (
              <Sources.DispatchCapacityFields
                key={dispatchCapacityItem.id}
                index={i}
                dispatch_capacity={dispatch_capacity_field_form}
                control={control}
                errors={errors}
                delivery_type={delivery_type}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_dispatch_capacity} />
          </div>
        </div>
      </FormSection>
      <FormSection title="Agendamiento" helperText="Agendamiento" number={8} anyError={validationsSectionsErrors[8]}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {schedule_field_form.fields.map((scheduleItem: any, i: number) => {
            return (
              <Sources.ScheduleFields
                key={scheduleItem.id}
                index={i}
                schedule={schedule_field_form}
                control={control}
                errors={errors}
                delivery_type={delivery_type}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_schedule} />
          </div>
        </div>
      </FormSection>
      <FormSection title="Crossdock" helperText="Crossdock" number={9} anyError={validationsSectionsErrors[9]}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {crossdock_field_form.fields.map((crossdockItem: any, i: number) => {
            return (
              <Sources.CrossdockFields
                key={crossdockItem.id}
                index={i}
                crossdock={crossdock_field_form}
                control={control}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_crossdock} />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Jornada de trabajo por tipo de producto"
        helperText="Jornada de trabajo por tipo de producto"
        number={10}
        anyError={validationsSectionsErrors[10]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {working_sessions_field_form.fields.map((workingSessionItem: any, i: number) => {
            return (
              <Sources.WorkingSessionsFields
                key={workingSessionItem.id}
                index={i}
                working_session={working_sessions_field_form}
                control={control}
                errors={errors}
                watch={watch}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_working_sessions} />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Jornada de trabajo general del source"
        helperText="Jornada de trabajo general del source"
        number={11}
        anyError={validationsSectionsErrors[11]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {working_time_field_form.fields.map((workingTimeItem: any, i: number) => {
            return (
              <Sources.WorkingTimeFields
                key={workingTimeItem.id}
                index={i}
                working_time={working_time_field_form}
                control={control}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_working_time} />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Configuración del calendario"
        helperText="Configuración del calendario"
        number={12}
        anyError={validationsSectionsErrors[12]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name={`calendarSettings.timezone`}
            defaultValue=""
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                label="Zona horaria"
                options={time_zone.map((element, i) => ({ id: i, label: element, value: element }))}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            name={`calendarSettings.nonWorkingDays`}
            defaultValue={[]}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                label="Días no laborales a la semana"
                options={DAYS_OF_WEEK_NUMBER}
                onChange={onChange}
                value={value}
                multi
              />
            )}
          />
          <Controller
            name={`calendarSettings.nonWorkingDates`}
            defaultValue={[]}
            control={control}
            render={() => (
              <CalendarSettingsDatesComponent
                name={`calendarSettings.nonWorkingDates`}
                watch={watch}
                setValue={setValue}
              />
            )}
          />
          {Boolean(["calendarSettings"].some((key: string) => Object.keys(errors).includes(key))) ? (
            <BadgeAlertForm />
          ) : null}
        </div>
      </FormSection>
    </div>
  )
}

export default FormSource
