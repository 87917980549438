import { Button, Collapse } from "components/Core"
import { Select } from "flowbite-react"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect, useState } from "react"

interface IFieldDeliveryType {
  deliveryType: any
  set_deliveryType: any
  apply_filter: any
}

const FieldDeliveryType = ({ deliveryType, set_deliveryType, apply_filter }: IFieldDeliveryType) => {
  const [delivery_types, set_delivery_types] = useState([])
  const { data, search_osrm_action } = useSearchMiddlewareOSRM({ entity: "schedules" })

  useEffect(() => {
    search_osrm_action({ from: 0, size: 1000, fields: ["id", "deliveryType"] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data?.total) {
      set_delivery_types(data?.records)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handle_change_deliveryType = (e: any) => {
    // e.preventDefault()
    set_deliveryType(e?.target?.value)
  }

  const apply_filter_deliveryType = () => {
    apply_filter({ deliveryType })
    set_deliveryType("")
  }

  return (
    <Collapse name="deliveryType" header={<h1>Tipo de entrega</h1>}>
      <div className="w-full flex flex-row items-center justify-center gap-2">
        <div className="relative w-full h-14">
          <Select onChange={handle_change_deliveryType} value={deliveryType} className="w-full h-full">
            {delivery_types.length ? (
              <>
                <option value="" disabled>
                  <p>Seleccione...</p>
                </option>
                {delivery_types.map((item: any, i: number) => (
                  <option key={i} value={item.deliveryType}>
                    <p>{item?.deliveryType}</p>
                  </option>
                ))}
              </>
            ) : (
              <option disabled>Sin opciones</option>
            )}
          </Select>
        </div>
        <Button label="Aplicar" className="w-28 !p-0" onClick={apply_filter_deliveryType} />
      </div>
    </Collapse>
  )
}

export default FieldDeliveryType
