import { Button, Collapse, TextField } from "components/Core"

interface IFieldId {
  id: any
  set_id: any
  apply_filter: any
}

const FieldId = ({ id, set_id, apply_filter }: IFieldId) => {
  const handle_change_id = (e: any) => {
    e.preventDefault()
    set_id(e?.target?.value)
  }

  const apply_filter_id = () => {
    apply_filter({ id })
    set_id("")
  }

  return (
    <Collapse name="id" header={<h1>Id</h1>}>
      <div className="w-full flex flex-row items-center justify-center gap-2">
        <TextField label="Id" onChange={handle_change_id} value={id} />
        <Button label="Aplicar" className="w-28 !p-0" onClick={apply_filter_id} />
      </div>
    </Collapse>
  )
}

export default FieldId
