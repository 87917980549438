import { Breadcrumb, ButtonGroup, FormSection, TitleScreen, DatePicker, TextField, Select } from "components/Core"
import useCapacityResources from "hooks/entities/useCapacityResources"
import useForm from "hooks/useForm"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import capacity_values from "utils/forms_default/capacity_values"
import schemaValidationCapacity from "utils/yup/capacity_schema"
import { CAPACITY_TYPE, ENTITY_TYPE, parsed_body_to_send } from "screens/CapacityScreen/capacity.config"
import CapacityEntityIdsByTypeComponent from "components/CapacityEntityIdsByTypeComponent"
import { useEffect, useState } from "react"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"

const NewScreen = () => {
  const navigate = useNavigate()
  const [delivery_type, set_delivery_type] = useState([])
  const { data: data_schedules, search_osrm_action: search_osrm_action_schedules } = useSearchMiddlewareOSRM({
    entity: "schedules",
  })
  const { control, errors, handleSubmit, watch, setValue } = useForm({
    defaultValues: capacity_values,
    schema: schemaValidationCapacity,
  })
  const entityType = watch("entityType")
  const { set_loading_app, put_update_max_capacity_action } = useCapacityResources()

  useEffect(() => {
    search_osrm_action_schedules({ from: 0, size: 1000, fields: ["id", "deliveryType"] })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const get_delivery_types_action = async () => {
      try {
        set_loading_app(true)
        let schedule_from_req = data_schedules?.records
        set_delivery_type(
          schedule_from_req?.map((sch: any) => ({
            id: sch?.id,
            label: sch?.deliveryType,
            value: sch?.deliveryType,
          })),
        )
        set_loading_app(false)
      } catch (error: any) {
        set_loading_app(false)
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    get_delivery_types_action()
    // eslint-disable-next-line
  }, [data_schedules])

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      console.log("body", body)
      await put_update_max_capacity_action({ body })
      Swal.fire({
        title: "Hecho",
        text: "Capacidad actualizada",
        icon: "success",
      })
      set_loading_app(false)
      navigate("/capacity")
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de capacidad usada", href: "/capacity" },
          { name: "Creación de capacidad usada", href: "/capacity/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="creación de capacidad usada" />
      </div>
      <form onSubmit={handleSubmit(on_submit)} className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <div className="w-full flex flex-col items-center gap-4">
          <FormSection title="Rango de fechas de reserva" helperText="Rango de fechas de reserva" number={1}>
            <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
              <div className="w-full flex flex-col lg:flex-row gap-4">
                <Controller
                  name="dateFrom"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange } }) => <DatePicker onChange={onChange} label="Inicio" />}
                />
                <Controller
                  name="dateTo"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange } }) => <DatePicker onChange={onChange} label="Fin" />}
                />
              </div>
              <Controller
                name="maxCapacity"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <TextField type="number" label="Cantidad a reservar" onChange={onChange} value={value} />
                )}
              />
              {Boolean(
                ["dateFrom", "dateTo", "maxCapacity"].some((key: string) => Object.keys(errors).includes(key)),
              ) ? (
                <BadgeAlertForm />
              ) : null}
            </div>
          </FormSection>
          <FormSection title="Tipo de producto y entrega" helperText="Tipo de producto" number={2}>
            <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
              <div className="w-full flex flex-col lg:flex-row gap-4">
                <Controller
                  name="productType"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextField label="Tipo de producto" onChange={onChange} value={value} />
                  )}
                />
                <Controller
                  name={`deliveryType`}
                  defaultValue=""
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select label="Tipo de entrega" options={delivery_type} onChange={onChange} value={value} />
                  )}
                />
              </div>
              {Boolean(["productType", "deliveryType"].some((key: string) => Object.keys(errors).includes(key))) ? (
                <BadgeAlertForm />
              ) : null}
            </div>
          </FormSection>
          <FormSection title="Detalle de reserva" helperText="Detalle de reserva" number={3}>
            <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
              <div className="w-full flex flex-col lg:flex-row gap-4">
                <Controller
                  name={`type`}
                  defaultValue=""
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select label="Tipo de capacidad" options={CAPACITY_TYPE} onChange={onChange} value={value} />
                  )}
                />
                <Controller
                  name={`entityType`}
                  defaultValue=""
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select label="Tipo de entidad" options={ENTITY_TYPE} onChange={onChange} value={value} />
                  )}
                />
              </div>
              {Boolean(["type", "entityType"].some((key: string) => Object.keys(errors).includes(key))) ? (
                <BadgeAlertForm />
              ) : null}
            </div>
          </FormSection>
          {Boolean(entityType?.value) ? (
            <FormSection closed={false} title="Detalle de entidad" helperText="Detalle de entidad" number={4}>
              <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
                <CapacityEntityIdsByTypeComponent entity={entityType?.value} name="entityIds" setValue={setValue} />
                {Boolean(["entityIds"].some((key: string) => Object.keys(errors).includes(key))) ? (
                <BadgeAlertForm />
              ) : null}
              </div>
            </FormSection>
          ) : null}
        </div>
      </form>
    </div>
  )
}

export default NewScreen
