enum OMNIX_MODULE {
  OOM = "oom",
  OIM = "oim",
  OLM = "olm",
  OSRM = "osrm",
  OMA = "oma",
  OSM = "osm",
  OITM = "oitm",
  MIDDLEWARE = "middleware",
  LISTENER = "listener",
  DOWNLOAD = "download",
  PRIORITIZATION = "prioritization",
  BFF = "bff",
  COMBINED = "combined"
}

export default OMNIX_MODULE