
import { Breadcrumb, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"

const DetailScreen = () => {
  const [route, set_route] = useState<any>()
  const { route_id } = useParams()
  const { data: data_routes, search_osrm_action: search_osrm_action_routes } = useSearchMiddlewareOSRM({
    entity: "routes",
  })

  useEffect(() => {
    search_osrm_action_routes({ from: 0, size: 1000, filters: { id: route_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_routes?.records.length)) {
      const get_route = async () => {
        let route_from_req = data_routes?.records[0]
        set_route(route_from_req)
      }
      get_route()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_routes])

  if (!route) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de rutas", href: "/routes" },
          { name: "Detalle de ruta", href: `/routes/${route_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title={`Detalle de ruta #${route_id}`} />
        {/* <p>SourceDetailScreen</p> */}
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-2 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[rgb(76,76,76)] px-4 py-3">Id de ruta</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{route_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado de la ruta</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{route?.status ? "Habilitado" : "Deshabilitado"}</p>
            </div>
          </div>
          <CollapseWithDetail title="Source" details={route?.source} />
          <CollapseWithDetail title="Target" details={route?.target} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Acciones</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
