const parsedDinamicDataOnSave = (field: any) => {
  let values: any = {}
  ;(field || []).map((item: any) => {
    if (item?.key?.includes(".")) {
      let [key1, key2] = item?.key?.split(".")
      if (values.hasOwnProperty(key1)) {
        values[key1] = { ...values[key1], [key2]: assignDataType(item.type, item.value) }
      } else {
        Object.assign(values, { [key1]: { [key2]: assignDataType(item.type, item.value) } })
      }
      return true
    }
    Object.assign(values, { [item.key]: assignDataType(item.type, item.value) })
    return true
  })
  return values
}

const assignDataType = (type: any, value: any) => {
  try {
    switch (type) {
      case "array-string":
        return value && value?.split("+")
      case "number":
        return Number(value) || 0
      case "string":
        return value?.toString() || JSON.stringify(value)
      default:
        return JSON.parse(value)
    }
  } catch (error) {
    return value
  }
}

export const TYPE = [
  { id: 1, label: "string", value: "string" },
  { id: 2, label: "boolean", value: "boolean" },
  { id: 3, label: "number", value: "number" },
  { id: 4, label: "array-string", value: "array-string" },
]

const ASSING_TYPEOF_LOAD = (value: any) => {
  try {
    const val = typeof value
    switch (val) {
      case "boolean":
        return "boolean"
      case "number":
        return "number"
      case "string":
        return "string"
      case "object":
        if (Array.isArray(value)) return "array-string"
        return "string"
      default:
        return "string"
    }
  } catch (error) {
    console.log("ERR", value)
    return value
  }
}

export const LOCATION_TYPE = [
  { id: 1, label: "Texto", value: "text" },
  { id: 2, label: "Coordenadas", value: "coordinates" },
]

export const parsed_body_to_send = (_data: any) => {
  const { id, criteria0, criteria1, criteria2, criterias, custom } = _data

  let data = {
    id,
    criteria0,
    criteria1,
    criteria2,
    custom: parsedDinamicDataOnSave(custom),
  }
  criterias?.map((cri: any, i: number) => {
    data = Object.assign(data, { [`criteria${i + 3}`]: cri?.value })
    return true
  })

  return data
}

export const fill_form_from_data = (_data: any) => {
  const { id, criteria0, criteria1, criteria2, criteria3, custom } = _data

  let data = {
    id,
    criteria0,
    criteria1,
    criteria2,
    criterias: [
      {
        type: typeof criteria3 === "string" ? "text" : "coordinates",
        value:
          typeof criteria3 === "string"
            ? criteria3
            : {
                lat: criteria3?.lat || "",
                lon: criteria3?.lat || "",
              },
      },
    ],
    custom: Object.entries(custom).map(([key, value]) => {
      return {
        key,
        value,
        type: TYPE.find((ty: any) => ty?.value === ASSING_TYPEOF_LOAD(value))?.value,
      }
    }),
  }
  return data
}
