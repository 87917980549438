import * as yup from "yup"

const schemaValidationCoverages = yup.object().shape({
  // 1
  id: yup.string().required("required-field"),
  status: yup.boolean(),
  // 2
  routeId: yup.string().required("required-field"),
  courierId: yup.string().required("required-field"),
  // 3
  shippingType: yup
    .array(yup.string())
    .min(1, "you-must-enter-at-least-1-shippingType")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
  deliveryType: yup.string().required("required-field"),
  // 4
  deliveryDays: yup.array(yup.number()),

  pickupDays: yup.array(yup.number()),
  // 5
  leadtime: yup.object().shape({
    value: yup.number().integer().min(0).required("required-field"),
    unit: yup.string().required("required-field"),
  }),
  // 6
  workingTime: yup.array(
    yup.object().shape({
      daysOfWeek: yup
        .array(yup.string())
        .min(1, "you-must-enter-at-least-1-day")
        .required("required-field")
        .typeError("you-must-enter-at-least-one-value"),
      from: yup.string().required("required-field"),
      to: yup.string().required("required-field"),
    }),
  ),
  // 7
  cost: yup
    .array(
      yup.object().shape({
        key: yup.string().required("required-field"),
        value: yup.string().required("required-field"),
      }),
    )
    .min(1, "you-must-enter-at-least-1-cost")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
})

export default schemaValidationCoverages
