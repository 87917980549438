import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import { useFieldArray } from "react-hook-form"
import coverage_values from "utils/forms_default/coverage_values"
import schemaValidationCoverages from "utils/yup/coverages_schema"
import { FormCoverage } from "components/FormEntities"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { parsed_body_to_send, fill_form_from_data } from "components/FormEntities/FormCoverage/coverage.config"
import useCoveragesResources from "hooks/entities/useCoveragesResources"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

const NewScreen = () => {
  const navigate = useNavigate()
  const [coverage, set_coverage] = useState<any>()
  const { coverage_id } = useParams()
  const { data: data_coverages, search_osrm_action: search_osrm_action_coverages } = useSearchMiddlewareOSRM({
    entity: "coverages",
  })
  const { control, errors, handleSubmit, reset } = useForm({
    defaultValues: coverage_values,
    schema: schemaValidationCoverages,
  })
  const { set_loading_app, put_upsert_coverage_action } = useCoveragesResources()
  const working_time_field_form = useFieldArray({ control, name: "workingTime" })
  const cost_field_form = useFieldArray({ control, name: "cost" })

  useEffect(() => {
    search_osrm_action_coverages({ from: 0, size: 1000, filters: { id: coverage_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_coverages?.records.length)) {
      const get_coverage = async () => {
        let coverage_from_req = data_coverages?.records[0]
        set_coverage(coverage_from_req)
      }
      get_coverage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_coverages])

  useEffect(() => {
    // RESET FORM TO FILL FIELDS
    coverage && reset(fill_form_from_data(coverage))
    // eslint-disable-next-line
  }, [coverage])

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      const res: any = await put_upsert_coverage_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Cobertura creado",
          icon: "success",
        })
        navigate(`/coverages/${body?.id}`)
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }

  if (!coverage) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de coberturas", href: "/coverages" },
          { name: "Edición de cobertura", href: "/coverages/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de cobertura" string_id={coverage_id} />
      </div>
      <form className="w-full flex flex-col py-2 px-4" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormCoverage
          control={control}
          errors={errors}
          working_time_field_form={working_time_field_form}
          cost_field_form={cost_field_form}
        />
      </form>
    </div>
  )
}

export default NewScreen
