export const fill_form_from_data = (_data: any) => {
  const {
    id,
    name,
    alias,
    shippingType,
    deliveryType,
    costStrategy,
    locationStrategy,
    productType,
    status,
    channel,
    criterias,
    dispatchCapacity,
    volumeCapacity,
    workingTime,
    nonWorkingDays,
    nonWorkingDates,
  } = _data

  let data = {
    // 1
    id,
    name,
    alias,
    // 2
    shippingType: shippingType ? (shippingType || [])?.map((shp: any) => shp) : [],
    deliveryType: deliveryType,
    // 3
    costStrategy: costStrategy,
    locationStrategy: locationStrategy,
    // 4
    productType: productType ? productType?.join(",") : "",
    status,
    channel: channel ? (channel || [])?.map((chn: any) => chn) : [],
    // 5
    criterias: criterias ? criterias?.join(",") : "",
    // 6
    dispatchCapacity: (dispatchCapacity || [])?.map((dp: any) => ({
      daysOfWeek: (dp?.daysOfWeek || [])?.map((day: any) => Number(day)),
      overCapacity: Number(dp?.overCapacity),
      capacity: Number(dp?.capacity),
    })),
    // 7
    volumeCapacity: (volumeCapacity || [])?.map((vc: any) => ({
      daysOfWeek: (vc?.daysOfWeek || [])?.map((day: any) => Number(day)),
      overCapacity: Number(vc?.overCapacity),
      capacity: Number(vc?.capacity),
    })),
    // 8
    workingTime: (workingTime || [])?.map((wt: any) => ({
      daysOfWeek: (wt?.daysOfWeek || [])?.map((day: any) => Number(day)),
      from: wt?.from,
      to: wt?.to,
    })),
    // 9
    nonWorkingDays: (nonWorkingDays || [])?.map((day: any) => Number(day)) || [],
    nonWorkingDates: nonWorkingDates || [],
  }

  return data
}

export const parsed_body_to_send = (_data: any) => {
  const {
    id,
    name,
    alias,
    shippingType,
    deliveryType,
    costStrategy,
    locationStrategy,
    productType,
    status,
    channel,
    criterias,
    dispatchCapacity,
    volumeCapacity,
    workingTime,
    nonWorkingDays,
    nonWorkingDates,
  } = _data

  let data = {
    // 1
    id,
    name,
    alias,
    // 2
    shippingType: shippingType ? shippingType?.map((shp: any) => shp) : [],
    deliveryType: deliveryType,
    // 3
    costStrategy,
    locationStrategy,
    // 4
    productType: productType ? productType?.split(",") : [],
    status,
    channel: channel ? channel?.map((chn: any) => chn) : [],
    // 5
    criterias: criterias ? criterias?.split(",") : [],
    // 6
    dispatchCapacity: (dispatchCapacity || [])?.map((dp: any) => ({
      daysOfWeek: (dp?.daysOfWeek || [])?.map((day: any) => Number(day)),
      overCapacity: Number(dp?.overCapacity),
      capacity: Number(dp?.capacity),
    })),
    // 7
    volumeCapacity: (volumeCapacity || [])?.map((vc: any) => ({
      daysOfWeek: (vc?.daysOfWeek || [])?.map((day: any) => Number(day)),
      overCapacity: Number(vc?.overCapacity),
      capacity: Number(vc?.capacity),
    })),
    // 8
    workingTime: (workingTime || [])?.map((wt: any) => ({
      daysOfWeek: (wt?.daysOfWeek || [])?.map((day: any) => Number(day)),
      from: wt?.from,
      to: wt?.to,
    })),
    // 9
    nonWorkingDays: (nonWorkingDays || [])?.map((day: any) => Number(day)) || [],
    nonWorkingDates: nonWorkingDates || [],
  }

  return data
}
