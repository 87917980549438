import { AddButton, FormSection, TextField } from "components/Core"
import { Controller } from "react-hook-form"
import { Locations } from "components/DinamicFormSections"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"

interface IFormLocation {
  control: any
  errors: any
  watch: any
  criterias_field_form: any
  custom_field_form: any
}

const FormLocation = ({ control, errors, watch, criterias_field_form, custom_field_form }: IFormLocation) => {
  const add_new_criteria = () =>
    criterias_field_form.append({
      type: "text",
      value: "",
    })
  const add_new_custom = () => custom_field_form.append({ type: "string", key: "", value: "" })

  const validationsSectionsErrors = {
    1: Boolean(["criteria0", "criteria1", "criteria2"].some((key: string) => Object.keys(errors).includes(key))),
    2: Boolean(["criterias"].some((key: string) => Object.keys(errors).includes(key))),
    3: Boolean(["custom"].some((key: string) => Object.keys(errors).includes(key))),
  }

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection
        title="Información general"
        helperText="Información general"
        number={1}
        anyError={validationsSectionsErrors[1]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="id"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField label="Id Ubicación" onChange={onChange} value={value} />
            )}
          />
          <Controller
            name="criteria0"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField label="criteria0" onChange={onChange} value={value} />
            )}
          />
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="criteria1"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="criteria1" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="criteria2"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="criteria2" onChange={onChange} value={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[1] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Criterias adicionales"
        helperText="Criterias adicionales"
        number={2}
        anyError={validationsSectionsErrors[2]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {criterias_field_form.fields.map((criteriaItem: any, i: number) => {
            return (
              <Locations.CriteriasFields
                key={criteriaItem.id}
                index={i}
                criterias={criterias_field_form}
                control={control}
                errors={errors}
                watch={watch}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            {criterias_field_form.fields.length >= 2 ? null : <AddButton onClick={add_new_criteria} />}
          </div>
          {validationsSectionsErrors[2] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection title="Custom" helperText="Custom" number={3} anyError={validationsSectionsErrors[3]}>
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {custom_field_form.fields.map((customItem: any, i: number) => {
            return (
              <Locations.CustomFields
                key={customItem.id}
                index={i}
                custom={custom_field_form}
                control={control}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            {custom_field_form.fields.length >= 4 ? null : <AddButton onClick={add_new_custom} />}
          </div>
          {validationsSectionsErrors[3] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
    </div>
  )
}

export default FormLocation
