import { Button, Collapse, TextField } from "components/Core"

interface IFieldName {
  name: any
  set_name: any
  apply_filter: any
}

const FieldName = ({ name, set_name, apply_filter }: IFieldName) => {
  const handle_change_name = (e: any) => {
    e.preventDefault()
    set_name(e?.target?.value)
  }

  const apply_filter_name = () => {
    apply_filter({ name })
    set_name("")
  }

  return (
    <Collapse name="name" header={<h1>Nombre</h1>}>
      <div className="w-full flex flex-row items-center justify-center gap-2">
        <TextField label="Nombre" onChange={handle_change_name} value={name} />
        <Button label="Aplicar" className="w-28 !p-0" onClick={apply_filter_name} />
      </div>
    </Collapse>
  )
}

export default FieldName
