import { useContext } from "react"
import AuthContext from "store/context/PublicContext"
import { AppContext } from "store/context/AppContext"
import useCancelToken from "hooks/useCancelToken"
import OMNIX_MODULE from "constants/OMNIX_MODULE"
import {
  post_module_entity_action_middleware,
} from "services/middleware_services"
import Swal from "sweetalert2"

// interface IGetSourceParams {
//   source_id: string
//   params?: any
// }

interface IPostSourceParams {
  body: any
}

const useCombinedSourcesBFF = () => {
  const { newCancelToken, isCancel } = useCancelToken()
  const {
    user: { token },
  } = useContext(AuthContext)
  const { loading_app, set_loading_app } = useContext(AppContext)

  const put_upsert_source_combined_action = async ({ body }: IPostSourceParams) => {
    try {
      return await post_module_entity_action_middleware(OMNIX_MODULE.COMBINED, `sources`, body, token, newCancelToken())
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  const post_list_source_combined_action = async ({ body }: IPostSourceParams) => {
    try {
      return await post_module_entity_action_middleware(OMNIX_MODULE.COMBINED, `sources/all`, body, token, newCancelToken())
    } catch (error: any) {
      set_loading_app(false)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      if (isCancel(error)) return
    }
  }

  return {
    post_list_source_combined_action,
    put_upsert_source_combined_action,
    loading_app,
    set_loading_app,
  }
}

export default useCombinedSourcesBFF
