import { Breadcrumb, TitleScreen, ButtonGroup } from "components/Core"
import useForm from "hooks/useForm"
import schemaValidationCouriers from "utils/yup/couriers_schema"
import courier_values from "utils/forms_default/courier_values"
import { useFieldArray } from "react-hook-form"
import { FormCourier } from "components/FormEntities"
import { useNavigate, useParams } from "react-router-dom"
import { parsed_body_to_send, fill_form_from_data } from "components/FormEntities/FormCourier/courier.config"
import { useEffect, useState } from "react"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import useCouriersResources from "hooks/entities/useCouriersResources"
import Swal from "sweetalert2"

const EditScreen = () => {
  const navigate = useNavigate()
  const [courier, set_courier] = useState<any>()
  const { courier_id } = useParams()
  const { control, errors, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: courier_values,
    schema: schemaValidationCouriers,
  })
  const { data: data_couriers, search_osrm_action: search_osrm_action_couriers } = useSearchMiddlewareOSRM({
    entity: "couriers",
  })
  const { set_loading_app, put_upsert_courier_action } = useCouriersResources()

  const volume_capacity_field_form = useFieldArray({ control, name: "volumeCapacity" })
  const dispatch_capacity_field_form = useFieldArray({ control, name: "dispatchCapacity" })
  const working_time_field_form = useFieldArray({ control, name: "workingTime" })

  useEffect(() => {
    search_osrm_action_couriers({ from: 0, size: 1000, filters: { id: courier_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_couriers?.records.length)) {
      const get_courier = async () => {
        let courier_from_req = data_couriers?.records[0]
        set_courier(courier_from_req)
      }
      get_courier()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_couriers])

  useEffect(() => {
    // RESET FORM TO FILL FIELDS
    courier && reset(fill_form_from_data(courier))
    // eslint-disable-next-line
  }, [courier])

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      const res: any = await put_upsert_courier_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Courier editado",
          icon: "success",
        })
        navigate(`/couriers/${body?.id}`)
      }
      set_loading_app(false)
    } catch (error: any) {
      console.log("error", error)
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  if (!courier) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de courier", href: "/couriers" },
          { name: "Edición de courier", href: `/couriers/${courier_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de courier" string_id={courier_id} />
      </div>
      <form onSubmit={handleSubmit(on_submit)} className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormCourier
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          volume_capacity_field_form={volume_capacity_field_form}
          dispatch_capacity_field_form={dispatch_capacity_field_form}
          working_time_field_form={working_time_field_form}
        />
      </form>
    </div>
  )
}

export default EditScreen
