import { format } from "date-fns"
import { es } from "date-fns/locale"

export const format_date = (date: Date, formatString: string = "yyyy-MM-dd") => {
  try {
    let dateTemp: Date = date
    return format(dateTemp, formatString, { locale: es })
  } catch (error) {
    return "-"
  }
}

export const formatManualTime = (time: string) => {
  try {
    let dateTemp
    if (time.includes("T")) {
      dateTemp = time.split("T")
      dateTemp = dateTemp[1]
      dateTemp = dateTemp.split(":")
      return `${dateTemp[0]}:${dateTemp[1]}`
    } else {
      dateTemp = time.split(":")
      return `${dateTemp[0]}:${dateTemp[1]}`
    }
  } catch (error) {
    return "--"
  }
}
