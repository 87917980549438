export const fill_form_from_data = (_data: any) => {
  const {
    id,
    status,
    routeId,
    courierId,
    shippingType,
    deliveryType,
    leadtime,
    workingTime,
    pickupDays,
    deliveryDays,
    cost,
  } = _data

  let data = {
    id,
    status,
    routeId,
    courierId,
    shippingType: shippingType ? (shippingType || [])?.map((shp: any) => shp) : [],
    deliveryType,
    leadtime,
    workingTime: (workingTime || [])?.map((wt: any) => ({
      daysOfWeek: (wt?.daysOfWeek || [])?.map((day: any) => Number(day)),
      from: wt?.from,
      to: wt?.to,
    })),
    deliveryDays: deliveryDays ? (deliveryDays || [])?.map((del_day: any) => Number(del_day)) : [],
    pickupDays: pickupDays ? (pickupDays || [])?.map((pck_day: any) => Number(pck_day)) : [],
    cost: Array.isArray(cost) ? loadCostFields(cost[0] || {}) : loadCostFields(cost || {}),
  }

  return data
}

export const parsed_body_to_send = (_data: any) => {
  const {
    id,
    status,
    routeId,
    courierId,
    shippingType,
    deliveryType,
    leadtime,
    workingTime,
    pickupDays,
    deliveryDays,
    cost,
  } = _data

  let data = {
    // 1
    id,
    status,
    // 2
    routeId,
    courierId,
    // 3
    shippingType: shippingType ? shippingType?.map((shp: any) => shp) : [],
    deliveryType: deliveryType,
    // 4
    deliveryDays: deliveryDays ? (deliveryDays || [])?.map((del_day: any) => Number(del_day)) : [],
    pickupDays: pickupDays ? (pickupDays || [])?.map((pck_day: any) => Number(pck_day)) : [],
    // 5
    leadtime: {
      value: Number(leadtime?.value),
      unit: leadtime?.unit,
    },
    // 6
    workingTime: (workingTime || [])?.map((wt: any) => ({
      daysOfWeek: wt?.daysOfWeek.map((day: any) => Number(day)),
      from: wt?.from,
      to: wt?.to,
    })),
    // 7
    cost: concatCostOnRequest(cost),
  }

  return data
}

const loadCostFields = (cost = {}) => {
  let values: any = []
  if (Object.keys(cost).length > 0) {
    Object.entries(cost).map((data) =>
      values.push({
        key: data[0],
        value: data[1],
      }),
    )
  }
  return values
}

const concatCostOnRequest = (arrayCost: any) => {
  let finalCost = {}
    ; (arrayCost || []).map((cost: any) =>
      Object.assign(finalCost, { [cost.key]: Number(cost.value) })
    )
  return finalCost
}
