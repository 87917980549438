import { useContext, useState } from "react"
import { authorizerService } from "services/middleware_services"
import AuthContext from "store/context/PublicContext"
import { AppContext } from "store/context/AppContext"
import useCancelToken from "hooks/useCancelToken"
import { Env, MultiClient } from "config"
import { SIMO_VAR, SIMO_VAR_POSTRENDER, SIMO_VAR_PRERENDER } from "constants/APP_CONSTANTS"
import { logInAction, logOutAction } from "store/actions/publicActions"
import { useNavigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import {
  auth0ClientId_dev,
  auth0ClientSecret_dev,
  auth0Audience_dev,
  auth0ClientId_uat,
  auth0ClientSecret_uat,
  auth0Audience_uat,
} from "constants/APP_CONSTANTS"

interface IErrorLogin {
  code: number
  message: string
}

const useAuthorizer = () => {
  const { logout } = useAuth0()
  let [, _env] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)
  const [error_login, set_error_login] = useState<IErrorLogin>({
    code: 0,
    message: "",
  })
  const { isCancel } = useCancelToken()
  const { dispatch } = useContext(AuthContext)
  const { set_loading_app } = useContext(AppContext)
  const navigate = useNavigate()

  const login_user_action = async () => {
    try {
      set_loading_app(true)
      set_error_login({
        code: 0,
        message: "",
      })
      // Temporal handler
      const cred_dev = {
        audience: auth0Audience_dev,
        grant_type: "client_credentials",
        client_id: auth0ClientId_dev,
        client_secret: auth0ClientSecret_dev,
      }

      const cred_uat = {
        audience: auth0Audience_uat,
        grant_type: "client_credentials",
        client_id: auth0ClientId_uat,
        client_secret: auth0ClientSecret_uat,
      }
      const credentials = _env === "dev" ? cred_dev : cred_uat
      const responseLogin = await authorizerService(credentials)
      const {
        data: { access_token },
      } = responseLogin
      dispatch(logInAction({ id: "guest", name: "Invitado", role: "guest", email: "guest", token: access_token }))
      set_loading_app(false)
      navigate("/dashboard")
    } catch (error: any) {
      set_loading_app(false)
      set_error_login({
        code: error?.response?.status,
        message: error.response?.data?.message,
      })
      if (isCancel(error)) return
    }
  }

  const logout_user_action = () => {
    localStorage.removeItem(SIMO_VAR)
    localStorage.removeItem(SIMO_VAR_PRERENDER)
    localStorage.removeItem(SIMO_VAR_POSTRENDER)
    dispatch(logOutAction)
    logout().then(() => window.location.reload())
  }

  return {
    error_login,
    login_user_action,
    logout_user_action,
  }
}

export default useAuthorizer
