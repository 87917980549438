import * as yup from "yup"

const schemaValidationLocation = yup.object().shape({
  id: yup.string().required("required-field"),
  criteria0: yup.string().required("required-field"),
  criteria1: yup.string().required("required-field"),
  criteria2: yup.string().required("required-field"),
  criterias: yup
    .array(
      yup.object().shape({
        type: yup.string().required(),
        value: yup.mixed().required(),
      }),
    )
    .min(1, "you-must-enter-at-least-1-location")
    .required("required-field")
    .typeError("you-must-enter-at-least-one-value"),
})

export default schemaValidationLocation
