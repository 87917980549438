import { Fragment, useCallback, useRef, useState, useMemo, useEffect } from "react"
import { Modal, TextField } from "components/Core"
import cn from "classnames"
import { IoClose } from "react-icons/io5"
import { IoIosAdd } from "react-icons/io"
import { Controller } from "react-hook-form"
import useForm from "hooks/useForm"
import usePreRender from "hooks/usePreRender"
import { ENTITY_TYPE } from "screens/CapacityScreen/capacity.config"
import * as yup from "yup"
import useCouriersResources from "hooks/entities/useCouriersResources"
import useBackendForFrontendResources from "hooks/useBackendForFrontendResources"
import Swal from "sweetalert2"

interface ICapacityEntityIdsByTypeComponent {
  name: string
  setValue: any
  entity: string
}

const CapacityEntityIdsByTypeComponent = ({ name, setValue, entity }: ICapacityEntityIdsByTypeComponent) => {
  const { _color } = usePreRender()
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {},
    schema: yup.object().shape({}),
  })
  const { set_loading_app, get_courier_action } = useCouriersResources()
  const { post_list_sources_action } = useBackendForFrontendResources()
  const [message, set_message] = useState<string>()
  const [ids_selected, set_ids_selected] = useState<Array<any>>([])
  const [ids_availables, set_ids_availables] = useState<Array<any>>([])
  const [show_modal_options, set_show_modal_options] = useState<boolean>(false)
  const modal_ref = useRef<any>(null)

  const ids_listed = useMemo(() => ids_selected, [ids_selected])

  useEffect(() => {
    setValue(name, ids_listed)
    // eslint-disable-next-line
  }, [ids_listed])

  const handleClickModal = useCallback(
    () => set_show_modal_options(!show_modal_options),
    [show_modal_options, set_show_modal_options],
  )

  const get_courier = async (id: string) => {
    try {
      set_loading_app(true)
      const res: any = await get_courier_action({ courier_id: id || "" })
      set_loading_app(false)
      return res?.data?.message
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
      return null
    }
  }

  const get_source = async (id: string) => {
    try {
      set_loading_app(true)
      const res: any = await post_list_sources_action({ body: { skipRecords: 0, maxRecords: 10, filter: { id: id } } })
      set_loading_app(false)
      return res?.data?.message[0] || null
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
      return null
    }
  }

  const add_entity_from_availables = (entity: any) => {
    let availables = ids_availables.filter((ids: any) => ids?.value !== entity?.value)
    set_ids_availables(availables)
    set_ids_selected([...ids_selected, entity])
  }

  const remove_entity_from_listed = (entity: any) => {
    let listed = ids_listed.filter((ids: any) => ids?.value !== entity?.value)
    set_ids_selected(listed)
  }

  const on_submit = async (data: any) => {
    set_message("")
    set_ids_availables([])
    reset()
    let action: any
    if (ENTITY_TYPE.find((ent: any) => ent.value === entity)?.label === "Courier") {
      action = await get_courier(data?.id)
    } else {
      action = await get_source(data?.id)
    }
    if (Boolean(action)) {
      set_ids_availables([{ id: action?.id, label: `${action?.id} - ${action?.name}`, value: action?.id }])
    } else {
      set_message(`No existen datos con el id ingresado -> ${data?.id}`)
    }
  }

  return (
    <Fragment>
      <div className="w-full flex flex-col">
        <TextField
          label="Ids entidades"
          disabled
          className="cursor-pointer"
          value={ids_listed.map((id: any) => id?.label + "\t")}
        />
        <div className="w-full flex items-center justify-end">
          <p onClick={handleClickModal} className="text-[#F9004D] underline cursor-pointer">
            Agregar Ids
          </p>
        </div>
      </div>
      <Modal mounted={show_modal_options} ref={modal_ref}>
        <div className="flex flex-col w-96">
          <div className={cn("w-full text-center rounded-t-xl p-2", `bg_${_color}`)}>
            <h4 className="text-lg font-medium uppercase text-white">{`Buscar ${
              ENTITY_TYPE.find((ent: any) => ent.value === entity)?.label
            }`}</h4>
          </div>
          <div className="w-full flex flex-col rounded-b-xl gap-4 px-4 py-6">
            <div className="w-full flex flex-col gap-2">
              <form onSubmit={handleSubmit(on_submit)}>
                <Controller
                  name="id"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextField label="Id" onChange={onChange} value={value} />
                  )}
                />
                <div className="flex flex-row items-center justify-end gap-2">
                  <button
                    onClick={() => set_ids_availables([])}
                    type="button"
                    className={cn(
                      "py-2 px-4 text-center text-white font-medium rounded-lg hover:opacity-90",
                      `border border_${_color} text_${_color}`,
                    )}
                  >
                    Limpiar
                  </button>
                  <button
                    type="submit"
                    className={cn(
                      "py-2 px-4 text-center text-white font-medium rounded-lg hover:opacity-90",
                      `bg_${_color}`,
                    )}
                  >
                    Buscar
                  </button>
                </div>
              </form>
              <div className="w-full flex flex-col gap-2">
                {Boolean(message) ? (
                  <p>{message}</p>
                ) : Boolean(ids_availables.length) ? (
                  <>
                    <h4 className="text-lg">
                      Disponibles: <span className="font-medium">{ids_availables.length}</span>
                    </h4>
                    <div className="w-full flex flex-row flex-wrap gap-2">
                      {ids_availables.map((entity: any, i: number) => (
                        <div key={i} className={cn("w-auto flex flex-row border rounded-md", `border_${_color}`)}>
                          <div className="w-full text-center py-1 px-2">
                            <p>{entity.label}</p>
                          </div>
                          <div
                            onClick={() => add_entity_from_availables(entity)}
                            className={cn("grid place-items-center p-1 cursor-pointer", `bg_${_color}`)}
                          >
                            <IoIosAdd className="text-lg text-white font-bold" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
              <hr />
              <div className="w-full flex flex-col gap-2">
                <h4 className="text-lg">
                  Seleccionados:{" "}
                  {Boolean(!ids_selected.length) ? <span className="italic text-[#F9004D]">Ninguno</span> : null}
                </h4>
                {Boolean(ids_listed.length) ? (
                  <div className="w-full flex flex-row flex-wrap gap-2">
                    {ids_listed.map((entity: any, i: number) => (
                      <div key={i} className={cn("w-auto flex flex-row border rounded-md", `border_${_color}`)}>
                        <div className="w-full text-center py-1 px-2">
                          <p>{entity?.label}</p>
                        </div>
                        <div
                          onClick={() => remove_entity_from_listed(entity)}
                          className={cn("grid place-items-center p-1 cursor-pointer", `bg_${_color}`)}
                        >
                          <IoClose className="text-lg text-white" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  )
}

export default CapacityEntityIdsByTypeComponent
