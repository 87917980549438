import { useReducer } from "react"
import publicReducer from "store/reducers/publicReducer"
import { createContext } from "react"
import { SIMO_VAR, SIMO_VAR_POSTRENDER, SIMO_VAR_PRERENDER } from "constants/APP_CONSTANTS"

interface initialState {
  logged: boolean
  user: any
  loadedPreRender: boolean
  loadedPostRender: boolean
  dispatch: any
}

const AuthContext = createContext({} as initialState)

const init = () => {
  const getUser = localStorage.getItem(SIMO_VAR)
  const user = getUser && JSON.parse(getUser)

  const alreadyPreRender = localStorage.getItem(SIMO_VAR_PRERENDER)
  const loadedPreRender = alreadyPreRender && Boolean(alreadyPreRender)
  const alreadyPostRender = localStorage.getItem(SIMO_VAR_POSTRENDER)
  const loadedPostRender = alreadyPostRender && Boolean(alreadyPostRender)

  return {
    logged: !!user,
    user: user,
    loadedPreRender,
    loadedPostRender,
  }
}

export const PublicProvider = ({ children }: { children: JSX.Element }) => {
  const [publicState, dispatch] = useReducer(publicReducer, {}, init)

  return (
    <AuthContext.Provider
      value={{
        ...publicState,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
