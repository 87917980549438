import { AddButton, FormSection, Select, Switch, TextField } from "components/Core"
import { Controller } from "react-hook-form"
import { HOURS_OF_DAY, SHIPPING_TYPES, UNIT_TIME } from "constants/FORM_CONSTANTS"
import { Schedule } from "components/DinamicFormSections"
import { estimate_type } from "./schedule.config"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"

interface IFormSchedule {
  is_edit?: boolean
  control: any
  errors: any
  watch: any
  delivery_slots_field_form: any
  rules_field_form: any
  working_sessions_field_form: any
}

const FormSchedule = ({
  is_edit,
  control,
  errors,
  watch,
  delivery_slots_field_form,
  rules_field_form,
  working_sessions_field_form,
}: IFormSchedule) => {
  const type = watch("type")
  const add_new_delivery_slot = () =>
    delivery_slots_field_form.append({ from: "", to: "", label: "", status: true, capacity: 0 })
  const add_new_rule = () =>
    rules_field_form.append({
      receptionTime: {
        daysOfWeek: [],
        from: "",
        to: "",
      },
      deliveryTime: {
        offset: {
          value: "",
          unit: "",
        },
        fixedHour: "",
        label: "",
      },
    })
  const add_new_working_session = () =>
    working_sessions_field_form.append({
      productType: "",
      from: "",
      to: "",
      enable_locktime: false,
      lockTime: {
        from: "",
        to: "",
      },
    })

  const is_type_rules = Boolean(type === "rules")

  const validationsSectionsErrors = {
    1: Boolean(
      ["id", "deliveryType", "shippingType", "datesToReturn"].some((key: string) => Object.keys(errors).includes(key)),
    ),
    2: Boolean(["status", "permit_enable_time", "enableTime"].some((key: string) => Object.keys(errors).includes(key))),
    3: Boolean(["type", "rules"].some((key: string) => Object.keys(errors).includes(key))),
    4: Boolean(["deliverySlots"].some((key: string) => Object.keys(errors).includes(key))),
    5: Boolean(["sourceDefaultSettings"].some((key: string) => Object.keys(errors).includes(key))),
    6: Boolean(["workingSessions"].some((key: string) => Object.keys(errors).includes(key))),
    7: Boolean(["productDefaultSettings"].some((key: string) => Object.keys(errors).includes(key))),
  }

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection
        closed={Boolean(!is_edit)}
        title="Información general"
        helperText="Información general"
        number={1}
        anyError={validationsSectionsErrors[1]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="id"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField label="Id Agendamiento" onChange={onChange} value={value} />
            )}
          />
          <Controller
            name="deliveryType"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField label="Tipo de entrega" onChange={onChange} value={value} />
            )}
          />
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="shippingType"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select label="Tipo de envío" options={SHIPPING_TYPES} onChange={onChange} value={value} multi />
              )}
            />
            <Controller
              name="datesToReturn"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField type="number" label="Cantidad de fechas a entregar" onChange={onChange} value={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[1] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        closed={Boolean(!is_edit)}
        title="Habilitar agendamiento"
        helperText="Habilitar agendamiento"
        number={2}
        anyError={validationsSectionsErrors[2]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Switch label="Estado del agendamiento" onChange={onChange} checked={value} />
              )}
            />
            <Controller
              name="permit_enable_time"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Switch label="Habilitar por rango de horario" onChange={onChange} checked={value} />
              )}
            />
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name={`enableTime.from`}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select label="Inicio" options={HOURS_OF_DAY} onChange={onChange} value={value} />
              )}
            />
            <Controller
              name={`enableTime.to`}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select label="Fín" options={HOURS_OF_DAY} onChange={onChange} value={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[2] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        closed={Boolean(!is_edit)}
        title="Tipo de estimación"
        helperText="Tipo de estimación"
        number={3}
        anyError={validationsSectionsErrors[3]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="type"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select label="Tipo" options={estimate_type} onChange={onChange} value={value} />
            )}
          />
          {is_type_rules && (
            <div className="w-full flex flex-col gap-4">
              {rules_field_form.fields.map((ruleItem: any, i: number) => {
                return (
                  <Schedule.RulesFields
                    key={ruleItem.id}
                    index={i}
                    rule={rules_field_form}
                    control={control}
                    errors={errors}
                  />
                )
              })}
              <div className="flex flex-row items-center justify-start">
                <AddButton onClick={add_new_rule} />
              </div>
            </div>
          )}
          {validationsSectionsErrors[3] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        closed={Boolean(!is_edit)}
        title="Slots de entrega"
        helperText="Slots de entrega"
        number={4}
        anyError={validationsSectionsErrors[4]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {delivery_slots_field_form.fields.map((deliverySlotItem: any, i: number) => {
            return (
              <Schedule.DeliverySlotsFields
                key={deliverySlotItem.id}
                index={i}
                deliverySlot={delivery_slots_field_form}
                control={control}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_delivery_slot} />
          </div>
        </div>
      </FormSection>
      <FormSection
        closed={Boolean(!is_edit)}
        title="Configuración por defecto sources"
        helperText="Configuración por defecto sources"
        number={5}
        anyError={validationsSectionsErrors[5]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-row items-center justify-start px-2">
            <h4 className="">Leadtime </h4>
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="sourceDefaultSettings.leadtime.value"
              defaultValue={""}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField label="Cantidad de tiempo" type="number" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="sourceDefaultSettings.leadtime.unit"
              defaultValue={""}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select label="Unidad de tiempo" options={UNIT_TIME} onChange={onChange} value={value} />
              )}
            />
          </div>
          <div className="w-full flex flex-row items-center justify-start px-2">
            <h4 className="">Crossdock </h4>
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="sourceDefaultSettings.crossdock.value"
              defaultValue={""}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField label="Cantidad de tiempo" type="number" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="sourceDefaultSettings.crossdock.unit"
              defaultValue={""}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select label="Unidad de tiempo" options={UNIT_TIME} onChange={onChange} value={value} />
              )}
            />
          </div>
          <div className="w-full flex flex-row items-center justify-start px-2">
            <h4 className="">Jornada de trabajo general del source </h4>
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name={`sourceDefaultSettings.workingTime.from`}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select label="Inicio" options={HOURS_OF_DAY} onChange={onChange} value={value} />
              )}
            />
            <Controller
              name={`sourceDefaultSettings.workingTime.to`}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select label="Fín" options={HOURS_OF_DAY} onChange={onChange} value={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[5] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        closed={Boolean(!is_edit)}
        title="Jornada de trabajo por tipo de producto del source"
        helperText="Jornada de trabajo por tipo de producto del source"
        number={6}
        anyError={validationsSectionsErrors[6]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col gap-4">
            {working_sessions_field_form.fields.map((working_sessionItem: any, i: number) => {
              return (
                <Schedule.WorkingSessionsFields
                  key={working_sessionItem.id}
                  index={i}
                  working_session={working_sessions_field_form}
                  control={control}
                  errors={errors}
                  watch={watch}
                />
              )
            })}
            <div className="flex flex-row items-center justify-start">
              <AddButton onClick={add_new_working_session} />
            </div>
          </div>
        </div>
      </FormSection>
      <FormSection
        closed={Boolean(!is_edit)}
        title="Configuración por defecto del producto"
        helperText="Configuración por defecto del producto"
        number={7}
        anyError={validationsSectionsErrors[7]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-row items-center justify-start px-2">
            <h4 className="">Leadtime </h4>
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="productDefaultSettings.leadtime.value"
              defaultValue={""}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField label="Cantidad de tiempo" type="number" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="productDefaultSettings.leadtime.unit"
              defaultValue={""}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select label="Unidad de tiempo" options={UNIT_TIME} onChange={onChange} value={value} />
              )}
            />
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="productDefaultSettings.volume"
              defaultValue={""}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField label="Volumen" type="number" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="productDefaultSettings.cost"
              defaultValue={""}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField label="Costo" type="number" onChange={onChange} value={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[7] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
    </div>
  )
}

export default FormSchedule
