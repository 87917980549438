import cn from "classnames"
import logo_omnix_white from "assets/img/logo_omnix_white.svg"
import { PiSpinnerGapBold } from "react-icons/pi"
import usePreRender from "hooks/usePreRender"
import usePostRender from "hooks/usePostRender"
import { Suspense, useEffect } from "react"
import { createPortal } from "react-dom"

const LoadingPostRender = () => {
  const { _generic_logo, _color } = usePreRender()

  const { loadedPostRender, handle_postrendered_action } = usePostRender()

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (!loadedPostRender) {
      timeout = setTimeout(() => handle_postrendered_action(true), 5000)
    }
    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [])

  const PoweredOmnix = () => (
    <div className="absolute bottom-4 flex flex-row justify-center items-center gap-4 text-white">
      <p className="text-sm">Powered by </p>
      <img alt="logo" className="w-24" src={logo_omnix_white} />
    </div>
  )

  const template = (
    <div className={cn("absolute top-0 left-0 w-screen h-screen grid place-content-center", `bg_${_color}`)}>
      <div className="flex flex-col items-center justify-center gap-4 lg:gap-6">
        <Suspense>
          <img alt="logo" className="w-60 lg:w-80" src={_generic_logo || logo_omnix_white} />
        </Suspense>
        <div className="flex flex-row items-center justify-center gap-2 text-white">
          <p>Estamos terminando de configurar todo para ti...</p>
          <PiSpinnerGapBold className="text-xl font-bold animate-[rotate_3s_linear_infinite]" />
        </div>
        {_generic_logo ? <PoweredOmnix /> : null}
      </div>
    </div>
  )

  return createPortal(template, document.body)
}

export default LoadingPostRender
