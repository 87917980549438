import { setPostRenderedAction } from "store/actions/publicActions"
import AuthContext from "store/context/PublicContext"
import { useContext } from "react"

const usePostRender = () => {
  const { loadedPostRender, dispatch } = useContext(AuthContext)

  const handle_postrendered_action = (type: boolean) => {
    dispatch(setPostRenderedAction(type))
  }

  return {
    loadedPostRender,
    handle_postrendered_action,
  }
}

export default usePostRender
