import React from 'react';
import { IoCheckmark } from 'react-icons/io5';
import { Option } from './types';
import clsx from 'classnames';

interface SelectOptionProps {
  option: Option;
  isSelected: boolean;
  onSelect: (option: Option) => void;
}

export const SelectOption: React.FC<SelectOptionProps> = ({
  option,
  isSelected,
  onSelect,
}) => (
  <div
    className={clsx(
      'p-2 cursor-pointer flex items-center justify-between',
      'hover:bg-gray-100 transition-colors',
      isSelected ? 'bg-blue-50' : ''
    )}
    onClick={() => onSelect(option)}
  >
    <span>{option.label}</span>
    {isSelected && <IoCheckmark size={16} className="text-blue-600" />}
  </div>
);