import { Button, Collapse, TextField } from "components/Core"

interface IFieldSalesChannelId {
  salesChannelId: any
  set_salesChannelId: any
  apply_filter: any
}

const FieldSalesChannelId = ({ salesChannelId, set_salesChannelId, apply_filter }: IFieldSalesChannelId) => {
  const handle_change_salesChannelId = (e: any) => {
    e.preventDefault()
    set_salesChannelId(e?.target?.value)
  }

  const apply_filter_salesChannelId = () => {
    apply_filter({ salesChannelId })
    set_salesChannelId("")
  }

  return (
    <Collapse name="salesChannelId" header={<h1>EOC</h1>}>
      <div className="w-full flex flex-row items-center justify-center gap-2">
        <TextField label="EOC" onChange={handle_change_salesChannelId} value={salesChannelId} />
        <Button label="Aplicar" className="w-28 !p-0" onClick={apply_filter_salesChannelId} />
      </div>
    </Collapse>
  )
}

export default FieldSalesChannelId
