import { AppContext } from "store/context/AppContext"
import cn from "classnames"
import { useLocation } from "react-router-dom"
import { useCallback, useContext, useEffect } from "react"
import { IoIosCloseCircle } from "react-icons/io"
import DataFilters from "./DataFilters"
import { Button, Chip } from "components/Core"

interface IFilters {
  className?: string
}

const Filters = ({ className }: IFilters) => {
  const { filters_table, open_table_filters, set_open_table_filters } = useContext(AppContext)
  const location = useLocation()

  useEffect(() => {
    set_open_table_filters(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handle_filters = useCallback(
    () => set_open_table_filters(!open_table_filters),
    [open_table_filters, set_open_table_filters],
  )

  const delete_active_filter = (key: string) => {
    filters_table.dispatch({ type: "DELETE_FILTER", payload: key })
  }

  const clear_filters = () => {
    filters_table.dispatch({ type: "CLEAR_FILTERS" })
  }

  return (
    <div className="absolute top-0 right-0 w-96 h-screen overflow-x-hidden">
      <div
        className={cn(
          "absolute w-full h-full top-0 right-0 flex flex-col items-center justify-between shadow-lg text-black bg-white z-40 transition-all",
          {
            "translate-x-96": !open_table_filters,
            "lg:translate-x-96": !open_table_filters,
          },
          className,
        )}
      >
        <div className="w-full flex flex-col justify-start items-center overflow-y-auto">
          <div className="w-full flex flex-row justify-between items-center p-4">
            <h4 className="text-xl font-semibold">Filtros</h4>
            <IoIosCloseCircle onClick={handle_filters} className="text-2xl text-red-600 cursor-pointer rounded-full" />
          </div>
          {Object.keys(filters_table.state).length ? (
            <div className="w-full flex flex-col justify-between items-center p-4 border-t-2">
              <h6 className="w-full text-md font-semibold">Filtros aplicados</h6>
              <div className="w-full flex flex-row flex-wrap gap-2 py-2">
                {Object.keys(filters_table.state).map(function (keyName, keyIndex) {
                  return (
                    <Chip
                      key={keyIndex}
                      label={`${keyName}: ${filters_table.state[keyName]}`}
                      onDelete={() => delete_active_filter(keyName)}
                    />
                  )
                })}
              </div>
            </div>
          ) : null}
          <DataFilters />
        </div>
        <div className="w-full p-4">
          {Object.keys(filters_table.state).length ? (
            <Button label="Limpiar Filtros" className="w-full" onClick={clear_filters} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Filters
