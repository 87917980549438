import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import schemaValidationSchedule from "utils/yup/schedule_schema"
import { useFieldArray } from "react-hook-form"
import schedule_values from "utils/forms_default/schedule_values"
import { useParams } from "react-router-dom"
import { FormSchedule } from "components/FormEntities"
import { parsed_body_to_send, fill_form_from_data } from "components/FormEntities/FormSchedule/schedule.config"
import { useEffect, useState } from "react"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import useScheduleResources from "hooks/entities/useScheduleResources"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

const EditScreen = () => {
  const navigate = useNavigate()
  const [schedule, set_schedule] = useState<any>()
  const { schedule_id } = useParams()
  const { data: data_schedules, search_osrm_action: search_osrm_action_schedules } = useSearchMiddlewareOSRM({
    entity: "schedules",
  })
  const { control, errors, handleSubmit, watch, reset } = useForm({
    defaultValues: schedule_values,
    schema: schemaValidationSchedule,
  })
  const { set_loading_app, put_upsert_schedule_action } = useScheduleResources()
  const slots_field_form = useFieldArray({ control, name: "slots" })
  const rules_field_form = useFieldArray({ control, name: "rules" })
  const working_sessions_field_form = useFieldArray({ control, name: "sourceDefaultSettings.workingSessions" })

  useEffect(() => {
    search_osrm_action_schedules({ from: 0, size: 1000, filters: { id: schedule_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_schedules?.records.length)) {
      const get_schedule = async () => {
        let schedule_from_req = data_schedules?.records[0]
        set_schedule(schedule_from_req)
      }
      get_schedule()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_schedules])

  useEffect(() => {
    // RESET FORM TO FILL FIELDS
    schedule && reset(fill_form_from_data(schedule))
    // eslint-disable-next-line
  }, [schedule])

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      const res: any = await put_upsert_schedule_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Agendamiento creado",
          icon: "success",
        })
        navigate("/schedule")
      }
      set_loading_app(false)
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  if (!schedule) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de agendamiento", href: "/schedule" },
          { name: "Edición de agendamiento", href: `/schedule/${schedule_id}/edit` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="Edición de agendamiento" string_id={schedule_id} />
      </div>
      <form className="w-full flex flex-col py-2 px-4" onSubmit={handleSubmit(on_submit)}>
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormSchedule
          is_edit
          control={control}
          errors={errors}
          watch={watch}
          slots_field_form={slots_field_form}
          rules_field_form={rules_field_form}
          working_sessions_field_form={working_sessions_field_form}
        />
      </form>
    </div>
  )
}

export default EditScreen
