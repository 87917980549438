import { FormSection, TextField, Select, AddButton } from "components/Core"
import { Couriers } from "components/DinamicFormSections"
import { Controller } from "react-hook-form"
import { DAYS_OF_WEEK_NUMBER, SHIPPING_TYPES } from "constants/FORM_CONSTANTS"
import CalendarSettingsDatesComponent from "components/CalendarSettingsDatesComponent"
import { useEffect, useState } from "react"
import BadgeAlertForm from "components/Core/Form/BadgeAlertForm"
import Swal from "sweetalert2"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"

interface IFormCourier {
  control: any
  watch: any
  errors: any
  setValue: any
  volume_capacity_field_form: any
  dispatch_capacity_field_form: any
  working_time_field_form: any
}

const FormCourier = ({
  control,
  errors,
  watch,
  setValue,
  volume_capacity_field_form,
  dispatch_capacity_field_form,
  working_time_field_form,
}: IFormCourier) => {
  const [delivery_type, set_delivery_type] = useState([])
  const [channels, set_channels] = useState([])
  const { data: data_channels, search_osrm_action: search_osrm_action_channels } = useSearchMiddlewareOSRM({
    entity: "channels",
  })
  const { data: data_schedules, search_osrm_action: search_osrm_action_schedules } = useSearchMiddlewareOSRM({
    entity: "schedules",
  })

  const cost_strategy_options = [
    { id: 1, label: "productType", value: "productType" },
    { id: 2, label: "volumeRange", value: "volumeRange" },
    { id: 3, label: "productTypeAndVolumeRange", value: "productTypeAndVolumeRange" },
  ]
  const location_strategy_options = [
    { id: 1, label: "level1", value: "level1" },
    { id: 2, label: "level2", value: "level2" },
    { id: 3, label: "level3", value: "level3" },
  ]

  useEffect(() => {
    search_osrm_action_channels({ from: 0, size: 1000, fields: ["id", "name"] })
    search_osrm_action_schedules({ from: 0, size: 1000, fields: ["id", "deliveryType"] })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const get_channels_and_delivery_types_action = async () => {
      try {
        let channels_from_req = data_channels?.records
        let schedule_from_req = data_schedules?.records
        set_channels(
          channels_from_req?.map((chl: any) => ({
            id: chl?.id,
            label: `${chl?.id} -> "${chl?.name}"`,
            value: chl?.id,
          })),
        )
        set_delivery_type(
          schedule_from_req?.map((sch: any) => ({
            id: sch?.id,
            label: sch?.deliveryType,
            value: sch?.deliveryType,
          })),
        )
      } catch (error: any) {
        Swal.fire({
          title: "Error",
          text: JSON.stringify(error?.response?.data?.message),
          icon: "error",
        })
      }
    }
    get_channels_and_delivery_types_action()
    // eslint-disable-next-line
  }, [data_channels, data_schedules])

  const add_new_volume_capacity = () =>
    volume_capacity_field_form.append({ daysOfWeek: [], capacity: "", overCapacity: "" })
  const add_new_dispatch_capacity = () =>
    dispatch_capacity_field_form.append({
      daysOfWeek: [],
      deliveryType: "",
      productType: "",
      dispatch: "",
      overCapacity: "",
    })
  const add_new_working_time = () =>
    working_time_field_form.append({
      daysOfWeek: [],
      from: "",
      to: "",
    })

  const validationsSectionsErrors = {
    1: Boolean(["id", "name", "alias"].some((key: string) => Object.keys(errors).includes(key))),
    2: Boolean(["shippingType", "deliveryType"].some((key: string) => Object.keys(errors).includes(key))),
    3: Boolean(["costStrategy", "locationStrategy"].some((key: string) => Object.keys(errors).includes(key))),
    4: Boolean(["productType", "status", "channel"].some((key: string) => Object.keys(errors).includes(key))),
    5: Boolean(["criterias"].some((key: string) => Object.keys(errors).includes(key))),
    6: Boolean(["dispatchCapacity"].some((key: string) => Object.keys(errors).includes(key))),
    7: Boolean(["volumeCapacity"].some((key: string) => Object.keys(errors).includes(key))),
    8: Boolean(["workingTime"].some((key: string) => Object.keys(errors).includes(key))),
    9: Boolean(["nonWorkingDays", "nonWorkingDates"].some((key: string) => Object.keys(errors).includes(key))),
  }

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <FormSection
        title="Información general"
        helperText="Información general"
        number={1}
        classNameContent="p-4"
        anyError={validationsSectionsErrors[1]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="id"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => <TextField label="Id" onChange={onChange} value={value} />}
          />
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Nombre del source" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="alias"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Alias (opcional)" onChange={onChange} value={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[1] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Detalles de Entrega y Despacho"
        helperText="Detalles de Entrega y Despacho"
        number={2}
        anyError={validationsSectionsErrors[2]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="shippingType"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select label="Tipo de envío" options={SHIPPING_TYPES} onChange={onChange} value={value} multi />
              )}
            />
            <Controller
              name="deliveryType"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select label="Tipo de entrega" options={delivery_type} onChange={onChange} value={value} />
              )}
            />
          </div>
          {validationsSectionsErrors[2] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Estrategias de costo y cobertura"
        helperText="Estrategias de costo y cobertura"
        number={3}
        anyError={validationsSectionsErrors[3]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="costStrategy"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select label="Estrategia de costo" options={cost_strategy_options} onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="locationStrategy"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Estrategia de cobertura"
                  options={location_strategy_options}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
          {validationsSectionsErrors[3] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Cobertura del courier"
        helperText="Cobertura del courier"
        number={4}
        anyError={validationsSectionsErrors[4]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <div className="w-full flex flex-col lg:flex-row gap-4">
            <Controller
              name="productType"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField label="Tipo de producto" onChange={onChange} value={value} />
              )}
            />
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Estado de cobertura"
                  options={[
                    { id: 1, label: "Habilitado", value: true },
                    { id: 2, label: "Deshabilitado", value: false },
                  ]}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
          <Controller
            name="channel"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <Select multi label="Canales" options={channels} onChange={onChange} value={value} />
            )}
          />
          {validationsSectionsErrors[4] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Segmentación del courier"
        helperText="Segmentación del courier"
        number={5}
        anyError={validationsSectionsErrors[5]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name="criterias"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField label="Criterias" onChange={onChange} value={value} />
            )}
          />
          {validationsSectionsErrors[5] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
      <FormSection
        title="Capacidad de despacho"
        helperText="Capacidad de despacho"
        number={6}
        anyError={validationsSectionsErrors[6]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {dispatch_capacity_field_form.fields.map((dispatchCapacityItem: any, i: number) => {
            return (
              <Couriers.DispatchCapacityFields
                key={dispatchCapacityItem.id}
                index={i}
                dispatch_capacity={dispatch_capacity_field_form}
                control={control}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_dispatch_capacity} />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Capacidad de volumen"
        helperText="Capacidad de volumen"
        number={7}
        anyError={validationsSectionsErrors[7]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {volume_capacity_field_form.fields.map((volumeCapacityItem: any, i: number) => {
            return (
              <Couriers.VolumeCapacityFields
                key={volumeCapacityItem.id}
                index={i}
                volume_capacity={volume_capacity_field_form}
                control={control}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_volume_capacity} />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Jornada de trabajo general del source"
        helperText="Jornada de trabajo general del source"
        number={8}
        anyError={validationsSectionsErrors[8]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          {working_time_field_form.fields.map((workingTimeItem: any, i: number) => {
            return (
              <Couriers.WorkingTimeFields
                key={workingTimeItem.id}
                index={i}
                working_time={working_time_field_form}
                control={control}
                errors={errors}
              />
            )
          })}
          <div className="flex flex-row items-center justify-start">
            <AddButton onClick={add_new_working_time} />
          </div>
        </div>
      </FormSection>
      <FormSection
        title="Configuración del calendario"
        helperText="Configuración del calendario"
        number={9}
        anyError={validationsSectionsErrors[9]}
      >
        <div className="w-full flex flex-col gap-4 lg:px-16 lg:py-10">
          <Controller
            name={`nonWorkingDays`}
            defaultValue={[]}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                label="Días no laborales a la semana"
                options={DAYS_OF_WEEK_NUMBER}
                onChange={onChange}
                value={value}
                multi
              />
            )}
          />
          <Controller
            name={`nonWorkingDates`}
            defaultValue={[]}
            control={control}
            render={() => <CalendarSettingsDatesComponent watch={watch} name={`nonWorkingDates`} setValue={setValue} />}
          />
          {validationsSectionsErrors[9] ? <BadgeAlertForm /> : null}
        </div>
      </FormSection>
    </div>
  )
}

export default FormCourier
