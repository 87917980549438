import useFiltersTable from "hooks/useFiltersTable"
import { useState } from "react"
import FieldId from "./fields/Id"
import {
  FieldSalesChannelId,
  FieldName,
  FieldCreationDate,
  FieldDeliveryType,
  FieldOrderCreationDate,
  FieldChannel,
  FieldSku,
} from "./fields"
import table_filters from "helpers/table_filters.json"
import { useLocation } from "react-router-dom"

const DataFilters = () => {
  const _table_filters: any = table_filters
  const { pathname } = useLocation()
  const { apply_filter } = useFiltersTable({
    table_name: "orders",
  })
  const [id, set_id] = useState("")
  const [name, set_name] = useState("")
  const [salesChannelId, set_salesChannelId] = useState("")
  const [deliveryType, set_deliveryType] = useState("")
  const [channel, set_channel] = useState("")
  const [sku, set_sku] = useState("")
  const [creationDate, set_creationDate] = useState({
    initial: new Date().toISOString(),
    final: new Date().toISOString(),
  })
  const [orderCreationDate, set_orderCreationDate] = useState({
    initial: new Date().toISOString(),
    final: new Date().toISOString(),
  })

  const current_table_filters: any = _table_filters.tables[pathname] || []

  const show_field = {
    id: {
      show: current_table_filters.includes("id"),
      component: <FieldId id={id} set_id={set_id} apply_filter={apply_filter} />,
    },
    sku: {
      show: current_table_filters.includes("sku"),
      component: <FieldSku sku={sku} set_sku={set_sku} apply_filter={apply_filter} />,
    },
    name: {
      show: current_table_filters.includes("name"),
      component: <FieldName name={name} set_name={set_name} apply_filter={apply_filter} />,
    },
    salesChannelId: {
      show: current_table_filters.includes("salesChannelId"),
      component: (
        <FieldSalesChannelId
          salesChannelId={salesChannelId}
          set_salesChannelId={set_salesChannelId}
          apply_filter={apply_filter}
        />
      ),
    },
    channel: {
      show: current_table_filters.includes("channel"),
      component: <FieldChannel channel={channel} set_channel={set_channel} apply_filter={apply_filter} />,
    },
    deliveryType: {
      show: current_table_filters.includes("deliveryType"),
      component: (
        <FieldDeliveryType
          deliveryType={deliveryType}
          set_deliveryType={set_deliveryType}
          apply_filter={apply_filter}
        />
      ),
    },
    creationDate: {
      show: current_table_filters.includes("creationDate"),
      component: (
        <FieldCreationDate
          creationDate={creationDate}
          set_creationDate={set_creationDate}
          apply_filter={apply_filter}
        />
      ),
    },
    orderCreationDate: {
      show: current_table_filters.includes("orderCreationDate"),
      component: (
        <FieldOrderCreationDate
          orderCreationDate={orderCreationDate}
          set_orderCreationDate={set_orderCreationDate}
          apply_filter={apply_filter}
        />
      ),
    },
  }

  return (
    <div className="relative w-full flex flex-col">
      {show_field?.id?.show ? show_field?.id?.component : null}
      {show_field?.sku?.show ? show_field?.sku?.component : null}
      {show_field?.salesChannelId?.show ? show_field?.salesChannelId?.component : null}
      {show_field?.name?.show ? show_field?.name?.component : null}
      {show_field?.channel?.show ? show_field?.channel?.component : null}
      {show_field?.creationDate?.show ? show_field?.creationDate?.component : null}
      {show_field?.orderCreationDate?.show ? show_field?.orderCreationDate?.component : null}
      {show_field?.deliveryType?.show ? show_field?.deliveryType?.component : null}
    </div>
  )
}

export default DataFilters
