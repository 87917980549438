import { Breadcrumb, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const DetailScreen = () => {
  const [coverage, set_coverage] = useState<any>()
  const { coverage_id } = useParams()
  const { data: data_coverages, search_osrm_action: search_osrm_action_coverages } = useSearchMiddlewareOSRM({
    entity: "coverages",
  })

  useEffect(() => {
    search_osrm_action_coverages({ from: 0, size: 1000, filters: { id: coverage_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_coverages?.records.length)) {
      const get_coverage = async () => {
        let coverage_from_req = data_coverages?.records[0]
        set_coverage(coverage_from_req)
      }
      get_coverage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_coverages])

  if (!coverage) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de coberturas", href: "/coverages" },
          { name: "Detalle de cobertura", href: `/coverages/${coverage_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title={`Detalle de cobertura #${coverage_id}`} />
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-2 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Id de cobertura</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{coverage_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Id de courier</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{coverage?.courierId}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Id de ruta</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{coverage?.routeId}</p>
            </div>
          </div>
          <CollapseWithDetail title="Jornada de trabajo general del source" details={coverage?.workingTime} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Detalle de cobertura</h4>
            <div className="grid grid-cols-2 grid-rows-3 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Tipo de envío</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{coverage?.shippingType}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Tipo de entrega</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{coverage?.deliveryType}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{coverage?.status ? "Habilitado" : "Deshabilitado"}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Leadtime</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{`${coverage?.leadtime?.value} - ${coverage?.leadtime?.unit}`}</p>
            </div>
          </div>
          <CollapseWithDetail title="Días de entrega" details={coverage?.deliveryDays} />
          <CollapseWithDetail title="Costo de la cobertura" details={coverage?.cost} />
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
