import { Breadcrumb, Select, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import {APIProvider, Map} from '@vis.gl/react-google-maps';
import { GOOGLE_MAPS_API_KEY } from "constants/API_URL_CONSTANTS";
import { Controller } from "react-hook-form";
import schemaValidationBusiness from "utils/yup/business_schema";
import cn from "classnames"
import usePreRender from "hooks/usePreRender"


const NewScreen = () => {
  const { control } = useForm({
    schema: schemaValidationBusiness,
  })

  const { _color } = usePreRender()

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de zonas", href: "/areas" },
          { name: "Creación de zona", href: "/areas/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="creación de zona" />
      </div>
      <form className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-6 py-2">
          <ButtonGroup />
        </div>
        <div className="w-full flex flex-col items-center gap-4">
          <div className="w-full flex flex-col rounded-lg border bg-white shadow-md">
            <div className="w-full flex flex-col p-4 lg:flex-row">
              <div className="w-full flex flex-col lg:flex-row lg:w-3/4 gap-6">
                <div className="w-full flex flex-col items-start justify-center gap-2">
                  <h4 className="font-medium">Comuna</h4>
                  <Controller
                    name="channel"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select label="Comuna" options={[]} onChange={onChange} value={value} />
                    )}
                  />
                </div>
                <div className="w-full flex flex-col items-start justify-center gap-2">
                  <h4 className="font-medium">a. Zona de exclusión</h4>
                  <Controller
                    name="shippingType"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select label="Zona de exclusión" options={[]} onChange={onChange} value={value} />
                    )}
                  />
                </div>
                <div className="w-full flex flex-col items-start justify-center gap-2">
                  <h4 className="font-medium">b. Zona de inclusión</h4>
                  <Controller
                    name="shippingType"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select label="Zona de inclusión" options={[]} onChange={onChange} value={value} />
                    )}
                  />
                </div>
              </div>
              <div className="w-full flex flex-col lg:w-1/4 items-center justify-center content-center gap-6">
                <button
                  type="submit"
                  className={cn(
                    "w-full lg:w-48 p-2 text-sm text-md hover:opacity-80 text-white rounded-md shadow-sm hover:shadow-md transform transition duration-500 hover:scale-105",
                    `bg_${_color}`,
                  )}
                >
                  Aplicar
                </button>
              </div>
            </div>
            <div className="w-full flex flex-col px-4 pb-4">
              <a href="#/" className="w-full flex flex-row items-center text-base font-medium text-red-500">
                <span className="flex text-lg text-red-500 pr-1">+</span> Zona de exclusión
              </a>
              <a href="#/" className="w-full flex flex-row items-center text-base font-medium text-emerald-500">
                <span className="flex text-lg text-emerald-500 pr-1">+</span> Zona de inclusión
              </a>
            </div>
          </div>
          <div className="w-full p-4 rounded-lg border bg-white shadow-md">
            <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
              <Map
                style={{width: '100%', height: '100vh'}}
                defaultCenter={{lat: 22.54992, lng: 0}}
                defaultZoom={3}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
              />
            </APIProvider>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewScreen
