import { Breadcrumb, TitleScreen } from "components/Core"
import CollapseWithDetail from "components/Core/Collapse/WithDetail"
import useSearchMiddlewareOSRM from "hooks/useSearchMiddlewareOSRM"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const DetailScreen = () => {
  const [courier, set_courier] = useState<any>()
  const { courier_id } = useParams()
  const { data: data_couriers, search_osrm_action: search_osrm_action_couriers } = useSearchMiddlewareOSRM({
    entity: "couriers",
  })

  useEffect(() => {
    search_osrm_action_couriers({ from: 0, size: 1000, filters: { id: courier_id } })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Boolean(data_couriers?.records.length)) {
      const get_courier = async () => {
        let courier_from_req = data_couriers?.records[0]
        set_courier(courier_from_req)
      }
      get_courier()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_couriers])

  if (!courier) return null
  return (
    <div className="w-full h-full flex flex-col items-start justify-start">
      <Breadcrumb
        data={[
          { name: "Listado de couriers", href: "/couriers" },
          { name: "Detalle de courier", href: `/couriers/${courier_id}` },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center py-4 px-6">
        <TitleScreen title={`Detalle de courier #${courier_id}`} />
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6 py-4 px-6">
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Información</h4>
            <div className="grid grid-cols-2 grid-rows-2 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Id de courier</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{courier_id}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Nombre</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{courier?.name}</p>
            </div>
          </div>
          <CollapseWithDetail title="Tipos de envío" details={courier?.shippingType} />
          <CollapseWithDetail title="Canales" details={courier?.channel} />
          <CollapseWithDetail title="Tipo de producto" details={courier?.productType} />
          <CollapseWithDetail title="Criterios" details={courier?.criterias} />
          <CollapseWithDetail title="Capacidad de volumen" details={courier?.volumeCapacity} />
          <CollapseWithDetail title="Capacidad de despacho" details={courier?.dispatchCapacity} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <h4 className="font-semibold text-[#4C4C4C]">Detalle del courier</h4>
            <div className="grid grid-cols-2 grid-rows-4 bg-white rounded-xl border divide-x divide-y">
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Alias</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{courier?.alias}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estado del courier</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{courier?.status ? "Habilitado" : "Deshabilitado"}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Tipo de entrega</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{courier?.deliveryType}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estrategia de costos</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{courier?.costStrategy}</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">Estrategia de ubicación</p>
              <p className="text-sm text-[#4C4C4C] px-4 py-3">{courier?.locationStrategy}</p>
            </div>
          </div>
          <CollapseWithDetail title="Jornadas laborales" details={courier?.workingTime} />
          <CollapseWithDetail title="Dias no laborables" details={courier?.nonWorkingDays} />
          <CollapseWithDetail title="Fechas no laborables" details={courier?.nonWorkingDates} />
        </div>
      </div>
    </div>
  )
}

export default DetailScreen
