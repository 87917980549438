import { LOGGING_IN, LOGGING_OUT, SET_POSTRENDERED, SET_PRERENDERED } from "store/types/publicTypes"
import { SIMO_VAR, SIMO_VAR_POSTRENDER, SIMO_VAR_PRERENDER } from "constants/APP_CONSTANTS"

export const logInAction = (user: any) => {
  localStorage.setItem(SIMO_VAR, JSON.stringify(user))
  return {
    type: LOGGING_IN,
    payload: user,
  }
}

export const logOutAction = () => {
  // localStorage.removeItem(SIMO_VAR);
  return { type: LOGGING_OUT }
}

export const setPreRenderedAction = (isPreRendered: boolean) => {
  localStorage.setItem(SIMO_VAR_PRERENDER, JSON.stringify(isPreRendered))
  return {
    type: SET_PRERENDERED,
    payload: isPreRendered,
  }
}

export const setPostRenderedAction = (isPostRendered: boolean) => {
  localStorage.setItem(SIMO_VAR_POSTRENDER, JSON.stringify(isPostRendered))
  return {
    type: SET_POSTRENDERED,
    payload: isPostRendered,
  }
}
