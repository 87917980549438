const publicReducer = (state: any, action: any) => {
  switch (action.type) {
    case "LOGGING_IN":
      return {
        ...state,
        logged: true,
        user: action.payload,
      }
    case "LOGGING_OUT":
      return {
        ...state,
        logged: false,
      }
    case "SET_PRERENDERED":
      return {
        ...state,
        loadedPreRender: action.payload,
      }
    case "SET_POSTRENDERED":
      return {
        ...state,
        loadedPostRender: action.payload,
      }
    default:
      return state
  }
}

export default publicReducer
