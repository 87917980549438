import { Button, Collapse, TextField } from "components/Core"

interface IFieldsku {
  sku: any
  set_sku: any
  apply_filter: any
}

const FieldSku = ({ sku, set_sku, apply_filter }: IFieldsku) => {
  const handle_change_sku = (e: any) => {
    e.preventDefault()
    set_sku(e?.target?.value)
  }

  const apply_filter_sku = () => {
    apply_filter({ sku })
    set_sku("")
  }

  return (
    <Collapse name="sku" header={<h1>Sku</h1>}>
      <div className="w-full flex flex-row items-center justify-center gap-2">
        <TextField label="sku" onChange={handle_change_sku} value={sku} />
        <Button label="Aplicar" className="w-28 !p-0" onClick={apply_filter_sku} />
      </div>
    </Collapse>
  )
}

export default FieldSku
