import { groupBy } from "helpers/tools"

export const estimate_type = [
  { id: 1, value: "leadtimes", label: "leadtimes" },
  { id: 2, label: "rules", value: "rules" },
]

export const parsed_body_to_send = (_data: any) => {
  const {
    id,
    type,
    deliveryType,
    shippingType,
    datesToReturn,
    status,
    slots,
    deliverySlots,
    rules,
    sourceDefaultSettings: { leadtime, crossdock, workingSessions, workingTime },
    productDefaultSettings,
    permit_enable_time,
    enableTime,
  } = _data

  let data = {
    // 1
    id,
    deliveryType,
    shippingType: shippingType ? shippingType?.map((shp: any) => shp) : [],
    datesToReturn: Number(datesToReturn),
    // 2
    status,
    // permit_enable_time -> See below,
    // 3
    type,
    // rules below
    // 4
    slots: (slots || []).map((item: any) => ({
      label: item.label,
      from: item?.from,
      to: item?.to,
    })),
    // 0
    // deliverySlots
    // 5
    sourceDefaultSettings: {
      leadtime: {
        unit: leadtime?.unit,
        value: Number(leadtime?.value),
      },
      crossdock: {
        unit: crossdock?.unit,
        value: Number(crossdock?.value),
      },
      // 6
      workingSessions: (workingSessions || []).map((ws: any) => {
        let wsData = {
          productType: ws.productType && ws.productType?.split(","),
          // capacity: ws.capacity,
          from: ws.from,
          to: ws.to,
        }
        if (Boolean(ws.enable_locktime)) {
          wsData = Object.assign(wsData, {
            lockTime: {
              from: ws.lockTime?.from,
              to: ws.lockTime?.to,
            },
          })
        }
        return wsData
      }),
      // 5
      workingTime: {
        from: workingTime?.from,
        to: workingTime?.to,
      },
    },
    // 7
    productDefaultSettings: {
      leadtime: {
        value: Number(productDefaultSettings?.leadtime?.value),
        unit: productDefaultSettings?.leadtime?.unit,
      },
      volume: Number(productDefaultSettings?.volume),
      cost: Number(productDefaultSettings?.cost),
    },
  }
  if (Boolean(deliverySlots)) {
    let _data_slots = (deliverySlots || []).map((item: any) => ({
      label: item.label,
      from: item?.from,
      to: item?.to,
      capacity: item?.capacity,
      status: item?.status,
      dayOfWeek: Number(item?.dayOfWeek),
    }))
    _data_slots = groupBy(_data_slots, "dayOfWeek")
    let _data_slots_parsed: any = {}

    Object.entries(_data_slots).forEach(([key, value]: any) => {
      _data_slots_parsed[key] = value?.map((sl: any) => ({
        label: sl.label,
        from: sl.from,
        to: sl.to,
        capacity: sl.capacity,
        status: sl.status,
      }))
    })

    data = Object.assign(data, {
      deliverySlots: _data_slots_parsed,
    })
  }
  if (Boolean(permit_enable_time) && enableTime && Object.values(enableTime).every(Boolean)) {
    data = Object.assign(data, {
      enableTime: {
        from: enableTime?.from,
        to: enableTime?.to,
      },
    })
  }
  if (Boolean(type === "rules")) {
    data = Object.assign(data, {
      rules: (rules || []).map((item: any) => ({
        receptionTime: {
          from: item?.receptionTime?.from,
          to: item?.receptionTime?.to,
          daysOfWeek: item?.receptionTime?.daysOfWeek && item?.receptionTime?.daysOfWeek.map((day: any) => day),
        },
        deliveryTime: {
          offset: {
            value: Number(item?.deliveryTime?.offset?.value),
            unit: item?.deliveryTime?.offset?.unit,
          },
          fixedHour: item?.deliveryTime?.fixedHour,
          label: item?.deliveryTime?.label,
        },
      })),
    })
  }

  return data
}

export const fill_form_from_data = (_data: any) => {
  if (!_data) return {}
  const {
    id,
    type,
    deliveryType,
    shippingType,
    datesToReturn,
    status,
    slots,
    deliverySlots,
    rules,
    sourceDefaultSettings: { leadtime, crossdock, workingSessions, workingTime },
    productDefaultSettings,
    enableTime,
  } = _data

  let data = {
    // 1
    id,
    deliveryType,
    shippingType: shippingType ? (shippingType || [])?.map((shp: any) => shp) : [],
    datesToReturn,
    // 2
    status,
    // permit_enable_time -> See below,
    // 3
    type,
    // 5
    sourceDefaultSettings: {
      leadtime: {
        unit: leadtime?.unit,
        value: leadtime?.value,
      },
      crossdock: {
        unit: crossdock?.unit,
        value: crossdock?.value,
      },
      // 6
      workingSessions: (workingSessions || []).map((ws: any) => {
        let wsData = {
          productType: ws.productType && ws.productType?.join(","),
          // capacity: ws.capacity,
          from: ws.from,
          to: ws.to,
        }
        if (Boolean(ws.lockTime)) {
          wsData = Object.assign(wsData, {
            enable_locktime: true,
            lockTime: {
              from: ws.lockTime?.from,
              to: ws.lockTime?.to,
            },
          })
        }
        return wsData
      }),
      // 5
      workingTime: {
        from: workingTime?.from,
        to: workingTime?.to,
      },
    },
    // 7
    productDefaultSettings: {
      leadtime: {
        value: productDefaultSettings?.leadtime?.value,
        unit: productDefaultSettings?.leadtime?.unit,
      },
      volume: productDefaultSettings?.volume,
      cost: productDefaultSettings?.cost,
    },
  }
  if (Boolean(enableTime) && Object.values(enableTime).every(Boolean)) {
    data = Object.assign(data, {
      permit_enable_time: true,
      enableTime: {
        from: enableTime?.from,
        to: enableTime?.to,
      },
    })
  }
  if (Boolean(rules)) {
    data = Object.assign(data, {
      rules: (rules || []).map((item: any) => ({
        receptionTime: {
          from: item?.receptionTime?.from,
          to: item?.receptionTime?.to,
          daysOfWeek: item?.receptionTime?.daysOfWeek && item?.receptionTime?.daysOfWeek.map((day: any) => day),
        },
        deliveryTime: {
          offset: {
            value: item?.deliveryTime?.offset?.value,
            unit: item?.deliveryTime?.offset?.unit,
          },
          fixedHour: item?.deliveryTime?.fixedHour,
          label: item?.deliveryTime?.label,
        },
      })),
    })
  }
  if (Boolean(slots)) {
    data = Object.assign(data, {
      slots: (slots || []).map((item: any) => ({
        label: item.label,
        from: item?.from,
        to: item?.to,
      })),
    })
  }
  if (Boolean(deliverySlots)) {
    data = Object.assign(data, {
      deliverySlots: (deliverySlots || []).map((item: any) => ({
        label: item.label,
        from: item?.from,
        to: item?.to,
        capacity: item?.capacity,
        status: item?.status,
      })),
    })
  }

  return data
}
