import { Breadcrumb, ButtonGroup, TitleScreen } from "components/Core"
import useForm from "hooks/useForm"
import location_values from "utils/forms_default/location_values"
import schemaValidationLocations from "utils/yup/locations_schema"
import { useFieldArray } from "react-hook-form"
import { FormLocation } from "components/FormEntities"
import { useNavigate } from "react-router-dom"
import useLocationsResources from "hooks/entities/useLocationsResources"
import Swal from "sweetalert2"
import { parsed_body_to_send } from "components/FormEntities/FormLocation/location.config"

const NewScreen = () => {
  const navigate = useNavigate()
  const { set_loading_app, put_upsert_location_action } = useLocationsResources()

  const { control, errors, watch, handleSubmit } = useForm({
    defaultValues: location_values,
    schema: schemaValidationLocations,
  })
  const criterias_field_form = useFieldArray({ control, name: "criterias" })
  const custom_field_form = useFieldArray({ control, name: "custom" })

  const on_submit = async (data: any) => {
    try {
      set_loading_app(true)
      const body = parsed_body_to_send(data)
      const res: any = await put_upsert_location_action({ body })
      if (res?.data?.code === 200) {
        Swal.fire({
          title: "Hecho",
          text: "Source creado",
          icon: "success",
        })
        set_loading_app(false)
        navigate(`/locations/${body?.id}`)
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error",
        text: JSON.stringify(error?.response?.data?.message),
        icon: "error",
      })
      set_loading_app(false)
    }
  }
  console.log("errors", errors)

  return (
    <div className="w-full h-full flex flex-col items-start justify-start pb-10">
      <Breadcrumb
        data={[
          { name: "Listado de ubicaciones", href: "/locations" },
          { name: "Creación de ubicación", href: "/locations/new" },
        ]}
      />
      <div className="w-full flex flex-col justify-center items-center pt-4 px-6">
        <TitleScreen title="creación de ubicación" />
      </div>
      <form onSubmit={handleSubmit(on_submit)} className="w-full flex flex-col py-2 px-4">
        <div className="w-full flex flex-row items-center justify-end lg:pb-10 py-2">
          <ButtonGroup />
        </div>
        <FormLocation control={control} errors={errors} watch={watch} criterias_field_form={criterias_field_form} custom_field_form={custom_field_form} />
      </form>
    </div>
  )
}

export default NewScreen
