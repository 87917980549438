import React from 'react';
import { IoClose } from 'react-icons/io5';
import { Option } from './types';

interface SelectedTagProps {
  option: Option;
  onRemove: (value: string) => void;
}

export const SelectedTag: React.FC<SelectedTagProps> = ({ option, onRemove }) => (
  <span
    className="bg-blue-100 text-blue-800 px-2 py-1 rounded-md text-sm flex items-center gap-1"
    onClick={(e) => {
      e.stopPropagation();
      onRemove(option.value);
    }}
  >
    {option.label}
    <IoClose size={14} className="cursor-pointer" />
  </span>
);